import faker from 'faker';
import { sample } from 'lodash';
import { paramCase } from 'change-case';
// utils
import mock from '../utils/mock';
import { mockImgProduct } from '../utils/mockImages';

// ----------------------------------------------------------------------

const PRODUCT_NAME = [
  'Emplois en centre d’appels',
  'Software Engineer (all levels) - Product Features & Infrastructure',
  'Software Development Engineer',
  "Agent de centre d'appels",
  'High-Level Tools Developer',
  'Software Engineer, iOS',
  'Guidewire Developer',
  'Chargée ou chargé de secrétariat',
  'Emplois en centre d’appels',
  'Software Engineer (all levels) - Product Features & Infrastructure',
  'Software Development Engineer',
  "Agent de centre d'appels",
  'High-Level Tools Developer',
  'Software Engineer, iOS',
  'Guidewire Developer',
  'Chargée ou chargé de secrétariat',
  'Emplois en centre d’appels',
  'Software Engineer (all levels) - Product Features & Infrastructure',
  'Software Development Engineer',
  "Agent de centre d'appels",
  'High-Level Tools Developer',
  'Software Engineer, iOS',
  'Guidewire Developer',
  'Chargée ou chargé de secrétariat'
];

const PRODUCT_COMPANY = [
  'TD',
  'DoorDash',
  'Apple',
  'Google',
  'Amazon',
  'Facebook',
  'Electronic Arts (EA)',
  'Ville de Montréal',
  'TD',
  'DoorDash',
  'Apple',
  'Google',
  'Amazon',
  'Facebook',
  'Electronic Arts (EA)',
  'Ville de Montréal',
  'TD',
  'DoorDash',
  'Apple',
  'Google',
  'Amazon',
  'Facebook',
  'Electronic Arts (EA)',
  'Ville de Montréal'
];

const PRODUCT_CITY = [
  'Bamako',
  'Segou',
  'Bamako',
  'Mopti',
  'Timbuktu',
  'Bamako',
  'Bamako',
  'Bamako',
  'Bamako',
  'Segou',
  'Bamako',
  'Mopti',
  'Timbuktu',
  'Bamako',
  'Bamako',
  'Bamako',
  'Bamako',
  'Segou',
  'Bamako',
  'Mopti',
  'Timbuktu',
  'Bamako',
  'Bamako',
  'Bamako'
];

const PRODUCT_EXTERNAL_LINK = [
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031',
  'https://carrieres.hema-quebec.qc.ca/TGnewUI/Search/home/HomeWithPreLoad?partnerid=30146&siteid=5031&PageType=JobDetails&jobid=35037#jobDetails=35037_5031'
];

const PRODUCT_COLOR = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107'
];

const PRODUCT_DESCRIPTION = `
<p><strong><small> SPECIFICATION</small></strong></p>
<p>Leather panels. Laces. Rounded toe. Rubber sole.
<br /><br />
<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>
<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>
`;

const PRODUCT_DESCRIPTIONS = [
  `
  <p><strong><small> Description</small></strong></p>
  <p>Do you want to be a part of the team that builds Amazon's Most Customer Centric Technologies? Our team builds software for a new Customer Relations Management (CRM) software for Amazon Customer Service that uses new cutting edge technologies and ML. Our intention is to reduce cognitive load on the Customer Service Associates while resolving customer issues. We are looking for Software Development Engineers who are results-oriented, data driven, innovative and passionate at creating new comprehensive customer engagement services/solutions. You’ll work on cool technologies such as such as GraphQL, Java, Spring and AWS to build the world’s most innovative customer experience solutions. As a Software Development Engineer on this team, you will collaborate with other experienced and senior software engineers to drive improvements to our technology, design and develop new customer facing products and interfaces, and build and track metrics to ensure high quality results. Your solutions will provide the ultimate customer service experience needed to anticipate and resolve customer's questions using our CRM. You should be somebody who enjoys working on complex system software, is customer-centric, and feels strongly about building a software system that can be operated at global scale. At Amazon, we are known for our customer obsession. We need your ideas and your ability to take initiative, innovate, and produce results as we continuously improve the customer experience. We use Scrum, test-driven development, and continuous deployment to deliver projects based on realistic timelines and resource constraints. We are seeking experienced Software Engineers excited by this opportunity. If this sounds interesting, we’d love to hear from you.
  <br /><br />
  <p><strong><small> Basic Qualifications</small></strong></p>
  <p>2+ years of non-internship professional software development experience
  <p>Programming experience with at least one modern language such as Java, C++, or C# including object-oriented design
  <p>1+ years of experience contributing to the architecture and design (architecture, design patterns, reliability and scaling) of new and current systems
  <br /><br />
  <p><strong><small> Preferred Qualifications</small></strong></p>
  <p>Bachelor’s Degree in Computer Science or related field 2+ years profession experience in enterprise AWS development
  <p>5+ years professional experience in software development
  <p>Computer Science fundamentals in object-oriented design
  <p>Computer Science fundamentals in data structures
  <p>Computer Science fundamentals in algorithm design, problem solving, and complexity analysis
  <p>Familiar with web technologies including Chromium OS & Browser
  <p>By submitting your application here, you can apply once to be considered for multiple Software Engineer openings across various Amazon teams. If you are successful in passing through the initial application review and assessment, you will be asked to submit your career and personal preferences so that our dedicated recruiters can match you to the right role based on these preferences.
  <br /><br />
  <p>Amazon is committed to providing accommodations at all stages through recruitment and employment in accordance with applicable human rights and accommodation legislation. If contacted for an employment opportunity, advise Human Resources if you require accommodation, including in order to apply for a position.
  `
];

const PRODUCT_SIZE = [
  '6',
  '7',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '13'
];

const products = [...Array(24)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba0-${setIndex}`,
    images: [...Array(8)].map((_, index) => {
      const setIndex = index + 1;
      return mockImgProduct(setIndex);
    }),
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
    priceSale:
      setIndex % 3
        ? null
        : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    totalRating: faker.datatype.number({ min: 0, max: 5, precision: 0.1 }),
    totalReview: faker.datatype.number(),
    ratings: [...Array(5)].map((_, index) => ({
      name: `${index + 1} Star`,
      starCount: faker.datatype.number(),
      reviewCount: faker.datatype.number()
    })),
    reviews: [...Array(8)].map((_, index) => ({
      id: faker.datatype.uuid(),
      name: faker.name.findName(),
      avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
      comment: faker.lorem.lines(),
      rating: faker.datatype.number({ min: 1, max: 5 }),
      isPurchased: faker.datatype.boolean(),
      helpful: faker.datatype.number({ min: 1, max: 500 }),
      postedAt: faker.date.past()
    })),
    colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,
    status: sample(['sale', 'new', '', '']),
    inventoryType: sample(['in_stock', 'out_of_stock', 'low_stock']),
    sizes: PRODUCT_SIZE,
    available:
      setIndex % 3 === 0 ? faker.datatype.number({ min: 19, max: 100 }) : 2,
    description: PRODUCT_DESCRIPTION,
    sold: faker.datatype.number(),
    createdAt: faker.date.past(),
    category: sample(['Accessories', 'Apparel', 'Shose']),
    gender: sample(['Men', 'Women', 'Kids']),
    descriptions: PRODUCT_DESCRIPTIONS[0],
    externalLink: PRODUCT_EXTERNAL_LINK[index],
    city: PRODUCT_CITY[index],
    company: PRODUCT_COMPANY[index],
    deadLine: faker.date.past(),
    owner: {
      photo: mockImgProduct(setIndex),
      name: 'Cheick Cisse',
      address_:
        'RE/MAX ELITE | 500 rue Notre-Dame Est, Victoriaville, Québec, G6P 4B5',
      email: 'cheickcisse@google.com',
      phone: '819-758-3293',
      id: '8864c717-587d-472a-929a-8e5f298024da-0',
      displayName: 'Jaydon Frankie',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg',
      phoneNumber: '+40 777666555',
      country: 'United States',
      address: '90210 Broadway Blvd',
      state: 'California',
      city: 'San Francisco',
      zipCode: '94116',
      about: '',
      role: 'admin',
      isPublic: true,
      companyName: 'Google'
    }
  };
});

// ----------------------------------------------------------------------

mock.onGet('/api/services').reply(200, { products });

// ----------------------------------------------------------------------

mock.onGet('/api/services/service').reply((config) => {
  try {
    const { name } = config.params;
    const product = products.find(
      (_product) => paramCase(_product.id) === name
    );

    if (!product) {
      return [404, { message: 'product not found' }];
    }

    return [200, { product }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
