import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';

// ----------------------------------------------------------------------

const rootReducer2 = (state, action) => {
  if (action.type === 'SIGNOUT_REQUEST') {
      // for all keys defined in your persistConfig(s)
      //storage.removeItem('persist:root')
      // storage.removeItem('persist:otherKey')
      return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer2),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
});

const persistor = persistStore(store);

export { store, persistor };
