import faker from 'faker';
import { sample } from 'lodash';
// utils
import mock from '../utils/mock';
import { mockCars, mockImo, mockElectronics, mockClothes } from '../utils/mockImages';

// ----------------------------------------------------------------------

const PRODUCT_NAME = [
  'Nike Air Force 1 NDESTRUKT',
  'Nike Space Hippie 04',
  'Nike Air Zoom Pegasus 37 A.I.R. Chaz Bear',
  'Nike Blazer Low 77 Vintage',
  'Nike ZoomX SuperRep Surge',
  'Zoom Freak 2',
  'Nike Air Max Zephyr',
  'Jordan Delta',
  'Air Jordan XXXV PF',
  'Nike Waffle Racer Crater',
  'Kyrie 7 EP Sisterhood',
  'Nike Air Zoom BB NXT',
  'Nike Air Force 1 07 LX',
  'Nike Air Force 1 Shadow SE',
  'Nike Air Zoom Tempo NEXT%',
  'Nike DBreak-Type',
  'Nike Air Max Up',
  'Nike Air Max 270 React ENG',
  'NikeCourt Royale',
  'Nike Air Zoom Pegasus 37 Premium',
  'Nike Air Zoom SuperRep',
  'NikeCourt Royale',
  'Nike React Art3mis',
  'Nike React Infinity Run Flyknit A.I.R. Chaz Bear'
];

const PRODUCT_NAME_IMMO = [
  'House for sale',
  'Quatruplex for sale',
  'Condo for sale',
  'Condo for sale',
  'Spanish house for sale',
  'Newly build house for sale',
  'Condo for sale',
  'House for sale',
  'House for sale',
  'House for sale',
  'Quatruplex for sale',
  'Newly build house for sale',
  'Quatruplex for sale',
  'Triplex for sale',
  'Quatruplex for sale',
  'Condo for sale',
  'Newly build house for sale',
  'House for sale',
  'NikeCourt Royale',
  'House for sale',
  'Newly build house for sale',
  'House for sale',
  'Newly build house for sale',
  'House for sale'
];

const PRODUCT_NAME_CARS = [
  '2016 Kia Rio LX+ A/C - BLUETOOTH',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2019 Mercedes-Benz S560',
  '2020 Mercedes-Benz S560 MB CERTIFIED',
  '2017 BMW X5 XDRIVE35D AWD BI TURBO DIESEL TOIT CUIR',
  '2020 Ferrari 458',
  '2018 GMC Terrain AWD 4dr SLE',
  '2018 Audi A5 Coupe PROGRESSIV',
  '2017 Audi Q3 2.0T PROGRESSIV QUATTRO',
  '2020 Kia Rio LX',
  '2019 Suzuki SX4',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2020 Audi Q5 3.0T QUATTRO',
  '2018 Mercedes-Benz S43 AMG',
  '2009 Suzuki SX4 JX AWD',
  '2018 Kia Rio LX Sport Model',
  '2020 Audi Q3 2.0T PROGRESSIV QUATTRO',
  '2016 Audi Q3 quattro 2.0T Technik',
  '2018 GMC Terrain SLE',
  '2016 GMC Terrain AWD 4dr -SLE-1',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2015 Ferrari 458 Speciale Aperta',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2018 Kia Rio LX+Auto CAMERA DE RECUL'
];

const PRODUCT_ADDRESS = [
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 1',
  '391, Rue Duhamel, Montréal (LaSalle)',
  'Avenue George-V, apt. 501, Montréal (Lachine), Neighbourhood East',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 2',
  '190, Rue Murray, apt. 1006, Montréal (Le Sud-Ouest), Neighbourhood Griffintown',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 3',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 4',
  '190, Rue Murray, apt. 1006, Montréal (Le Sud-Ouest), Neighbourhood Griffintown',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 5',
  '391, Rue Duhamel, Montréal (LaSalle)',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 6',
  '391, Rue Duhamel, Montréal (LaSalle)',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  'Avenue George-V, apt. 501, Montréal (Lachine), Neighbourhood East',
  '391, Rue Duhamel, Montréal (LaSalle)',
  '190, Rue Murray, apt. 1006, Montréal (Le Sud-Ouest), Neighbourhood Griffintown',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 7',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  '190, Rue Murray, apt. 1006, Montréal (Le Sud-Ouest), Neighbourhood Griffintown',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 8',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  'Avenue George-V, apt. 501, Montréal (Lachine), Neighbourhood East'
];

const PRODUCT_ADDRESS_CARS = [
  'Olivier KIA McMasterville',
  'Mercedes-Benz Laval',
  'Mercedes-Benz Laval',
  'Mercedes-Benz Laval',
  'St-Jérôme Chevrolet Buick GMC Corvette',
  'Ferrari Québec à Montréal',
  'Chevrolet Buick GMC West Island',
  'Gravel Chevrolet Buick Cadillac GMC Île-des-Soeurs',
  'Audi West-Island',
  'Chomedey Toyota Laval',
  'Olivier KIA McMasterville',
  'Mercedes-Benz Laval',
  'Audi North-Island',
  'St-Jérôme Chevrolet Buick GMC Corvette',
  'Chomedey Toyota Laval',
  'Olivier KIA McMasterville',
  'Audi West-Island',
  'Gravel Chevrolet Buick Cadillac GMC Île-des-Soeurs',
  'Chevrolet Buick GMC West Island',
  'Chevrolet Buick GMC West Island',
  'Mercedes-Benz Laval',
  'Ferrari Québec à Montréal',
  'Mercedes-Benz Laval',
  'St-Jérôme Chevrolet Buick GMC Corvette'
];

const PRODUCT_TYPE = [
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell'
];

const PRODUCT_COLOR = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107'
];

const PRODUCT_DESCRIPTION = `
<p><strong><small> DESCRIPTION</small></strong></p>
<p>Belle maison possédant 4 chambres à coucher et un grand terrain intime bordé de haies avec arbres mature. Secteur tranquille près de tous les services. La véranda 3 saisons est l'endroit idéal pour recevoir. Un accès sur le côté de la résidence vous permet d'y stationner un motorisé ou un bateau. Sous sol aménagé avec poêle au bois. C'est à voir!.
`;

const PRODUCT_DESCRIPTION_CARS = `
<p>OUVERT LE SAMEDI! Osez comparer! Chomedey Toyota est la concession par excellence à Laval depuis 40 ans. Offrant une vaste gamme de véhicules d'occasion. Chomedey Toyota saura faire la différence dans votre sélection de véhicule. Prenez rendez-vous avec l'un de nos conseillers sans tarder. 1ere, 2ième, 3ième chance au crédit. Prolongation de garantie à prix concurrentiel. Rapport d'historique Carfax disponible! chomedeytoyota.ca / 1-833-368-1069 ---- OPEN ON SATURDAY! Dare to compare! Chomedey Toyota has been the dealership by excellence in Laval for 40 years. Offering a wide range of used vehicles. Chomedey Toyota will make the difference in your vehicle selection. Make an appointment with one of our advisors without delay. 1st, 2nd, 3rd chance credit. Competitively priced warranty extension. Carfax history report available!
`;

const PRODUCT_SIZE = [
  '6',
  '7',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '13'
];

const PRODUCT_BRAND_CARS = [
  'Kia',
  'Mercedes-Benz',
  'Mercedes-Benz',
  'Mercedes-Benz',
  'BMW',
  'Ferrari',
  'GMC',
  'Audi',
  'Audi',
  'Kia',
  'Suzuki',
  'Mercedes-Benz',
  'Audi',
  'Mercedes-Benz',
  'Suzuki',
  'Kia',
  'Audi',
  'Audi',
  'GMC',
  'GMC',
  'Mercedes-Benz',
  'Ferrari',
  'Mercedes-Benz',
  'Kia'
];

const PRODUCT_MODEL_CARS = [
  'Rio',
  'S63 AMG',
  'S560',
  'S560',
  'X5',
  '458',
  'Terrain',
  'A5',
  'Q3',
  'Rio',
  'SX4',
  'S63',
  'Q5',
  'S43 AMG',
  'SX4',
  'Rio LX',
  'Q3',
  'Q3',
  'Terrain SLE',
  'Terrain',
  'S63',
  '458 Speciale Aperta',
  'S63',
  'Rio'
];

const PRODUCT_YEAR_CARS = [
  '2016',
  '2018',
  '2019',
  '2020',
  '2017',
  '2020',
  '2018',
  '2018',
  '2017',
  '2020',
  '2019',
  '2018',
  '2020',
  '2018',
  '2009',
  '2018',
  '2020',
  '2016',
  '2018',
  '2016',
  '2018',
  '2015',
  '2018',
  '2018'
];

const PRODUCT_TRANSMISSION_CARS = [
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'manual',
  'automatic',
  'manual',
  'automatic',
  'automatic',
  'automatic',
  'manual',
  'automatic',
  'manual',
  'automatic',
  'automatic',
  'manual',
  'automatic',
  'manual'
];

const PRODUCT_ENGINE_CARS = [
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L'
];

const PRODUCT_BODY_TYPE_CARS = [
  'suv',
  'suv',
  'sedan',
  'truck',
  'van',
  'van',
  'truck',
  'electric',
  'electric',
  'suv',
  'big_truck',
  'suv',
  'suv',
  'sedan',
  'truck',
  'van',
  'van',
  'truck',
  'electric',
  'electric',
  'suv',
  'big_truck',
  'suv',
  'suv'
];

const PRODUCT_CONDITION_CARS = [
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new'
];

const PRODUCT_EXTERIOR_COLOR_CARS = [
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black'
];

const PRODUCT_INTERIOR_COLOR_CARS = [
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black'
];

const PRODUCT_FUEL_CARS = [
  'Essence',
  'Essence',
  'Disel',
  'Essence',
  'Essence',
  'Essence',
  'Disel',
  'Essence,',
  'Essence',
  'Essence',
  'Disel',
  'Essence',
  'Essence',
  'Essence',
  'Disel',
  'Essence',
  'Essence',
  'Essence',
  'Disel',
  'Essence',
  'Essence',
  'Essence',
  'Disel',
  'Essence'
];

const PRODUCT_DOORS_CARS = [
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4'
];

const PRODUCT_FEATURES_CARS = [
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ]
];

const productFeature = (index) => PRODUCT_FEATURES_CARS[index];

const PRODUCT_COLOR_CARS = [
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000']
];

const PRODUCT_PASSENGERS_CARS = [
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5'
];

const PRODUCT_TYPE_CARS = [
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell'
];

const PRODUCT_NAME_ELECTRICS = [
  'Apple iMac 24',
  'Sony Black Playstation 4 Controller',
  'Apple iPhone 5',
  'Apple iPad',
  'Macbook Air Apple',
  'Sony Camera',
  'Canon Camera',
  'Canon Camera',
  'Old TV ',
  'Macbook Pro from Apple',
  'Apple iPad mini',
  'Apple Macbook Pro',
  'Very Very Old Tv Unknown Brand',
  'Dell Laptop',
  'Canon Camera',
  'Dell Laptop',
  'Google Pixel',
  'iPad 3 Apple',
  'PS5 Sony',
  'iPhone Xs Apple',
  'iPhone X Apple',
  'MacBook Pro 2015',
  'Tablet Unknown brand',
  'Apple iPhone 11'
];

const PRODUCT_SIZE_AVAILABILITY_ELECTRICS = {
  xs: {
    available: 0,
    us: 'XS',
    eu: 'XS'
  },
  s: {
    available: 0,
    us: 'S',
    eu: 'S'
  },
  m: {
    available: 0,
    us: 'M',
    eu: 'M'
  },
  l: {
    available: 0,
    us: 'L',
    eu: 'L'
  },
  xl: {
    available: 0,
    us: 'XL',
    eu: 'XL'
  },
  0: {
    available: 1,
    us: 'None',
    eu: 'None'
  },
  1: {
    available: 0,
    us: '1',
    eu: '31'
  },
  2: {
    available: 0,
    us: '2',
    eu: '32'
  },
  3: {
    available: 0,
    us: '3',
    eu: '33'
  },
  4: {
    available: 0,
    us: '4',
    eu: '34'
  },
  5: {
    available: 0,
    us: '5',
    eu: '35'
  },
  6: {
    available: 0,
    us: '6',
    eu: '38'
  },
  7: {
    available: 0,
    us: '7',
    eu: '39.5'
  },
  8: {
    available: 0,
    us: '8',
    eu: '40.5'
  },
  8.5: {
    available: 0,
    us: '8.5',
    eu: '41.5'
  },
  9: {
    available: 0,
    us: '9',
    eu: '41.5'
  },
  9.5: {
    available: 0,
    us: '9.5',
    eu: '42.5'
  },
  10: {
    available: 0,
    us: '10',
    eu: '38'
  },
  10.5: {
    available: 0,
    us: '10.5',
    eu: '43.5'
  },
  11: {
    available: 0,
    us: '11',
    eu: '44.5'
  },
  11.5: {
    available: 0,
    us: '11.5',
    eu: '45.5'
  },
  12: {
    available: 0,
    us: '12',
    eu: '46'
  },
  13: {
    available: 0,
    us: '13',
    eu: '47'
  },
  14: {
    available: 0,
    us: '14',
    eu: '48'
  },
  15: {
    available: 0,
    us: '15',
    eu: '49'
  },
  15: {
    available: 0,
    us: '16',
    eu: '50'
  }
};

const PRODUCT_CATEGORY_ELECTRICS = [
  'computer',
  'game',
  'phone',
  'tablet',
  'computer',
  'camera',
  'camera',
  'camera',
  'tv',
  'computer',
  'tablet',
  'computer',
  'tv',
  'computer',
  'camera',
  'computer',
  'phone',
  'tablet',
  'game',
  'phone',
  'phone',
  'computer',
  'tablet',
  'phone'
];

const PRODUCT_SIZE_AVAILABILITY_CLOTHES = {
  xs: {
    available: 70,
    us: 'XS',
    eu: 'XS'
  },
  s: {
    available: 7,
    us: 'S',
    eu: 'S'
  },
  m: {
    available: 9,
    us: 'M',
    eu: 'M'
  },
  l: {
    available: 9,
    us: 'L',
    eu: 'L'
  },
  xl: {
    available: 87,
    us: 'XL',
    eu: 'XL'
  },
  0: {
    available: 1,
    us: 'None',
    eu: 'None'
  },
  1: {
    available: 0,
    us: '1',
    eu: '31'
  },
  2: {
    available: 0,
    us: '2',
    eu: '32'
  },
  3: {
    available: 0,
    us: '3',
    eu: '33'
  },
  4: {
    available: 0,
    us: '4',
    eu: '34'
  },
  5: {
    available: 0,
    us: '5',
    eu: '35'
  },
  6: {
    available: 0,
    us: '6',
    eu: '38'
  },
  7: {
    available: 0,
    us: '7',
    eu: '39.5'
  },
  8: {
    available: 0,
    us: '8',
    eu: '40.5'
  },
  8.5: {
    available: 0,
    us: '8.5',
    eu: '41.5'
  },
  9: {
    available: 0,
    us: '9',
    eu: '41.5'
  },
  9.5: {
    available: 0,
    us: '9.5',
    eu: '42.5'
  },
  10: {
    available: 0,
    us: '10',
    eu: '38'
  },
  10.5: {
    available: 0,
    us: '10.5',
    eu: '43.5'
  },
  11: {
    available: 0,
    us: '11',
    eu: '44.5'
  },
  11.5: {
    available: 0,
    us: '11.5',
    eu: '45.5'
  },
  12: {
    available: 0,
    us: '12',
    eu: '46'
  },
  13: {
    available: 0,
    us: '13',
    eu: '47'
  },
  14: {
    available: 0,
    us: '14',
    eu: '48'
  },
  15: {
    available: 0,
    us: '15',
    eu: '49'
  },
  15: {
    available: 0,
    us: '16',
    eu: '50'
  }
};

const PRODUCT_CATEGORY_CLOTHES = [
  'tshirt',
  'shirt',
  'pants',
  'coat',
  'sweat_shirt',
  'dress',
  'skirt',
  'traditional_clothes',
  'tshirt',
  'shirt',
  'pants',
  'coat',
  'sweat_shirt',
  'dress',
  'skirt',
  'traditional_clothes',
  'tshirt',
  'shirt',
  'pants',
  'coat',
  'sweat_shirt',
  'dress',
  'skirt',
  'traditional_clothes',
  'tshirt',
  'shirt',
  'pants',
  'coat',
  'sweat_shirt',
  'dress',
  'skirt',
  'traditional_clothes'
];

const PRODUCT_GENRE = [
  'men',
  'women',
  'kids',
  'women',
  'men',
  'women',
  'kids',
  'women',
  'men',
  'women',
  'kids',
  'women',
  'men',
  'women',
  'kids',
  'women',
  'men',
  'women',
  'kids',
  'women',
  'men',
  'women',
  'kids',
  'women'
];

const productsclothes = [...Array(24)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba0-clothe-${setIndex}`,
    cover: mockClothes(setIndex),
    images: [...Array(8)].map((_, index) => {
      const setIndex = index + 1;
      return mockClothes(setIndex);
    }),
    allowBuy: false,
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
    priceSale:
      setIndex % 3
        ? null
        : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    totalRating: faker.datatype.number({ min: 0, max: 5, precision: 0.1 }),
    totalReview: faker.datatype.number(),
    ratings: [...Array(5)].map((_, index) => ({
      name: `${index + 1} Star`,
      starCount: faker.datatype.number(),
      reviewCount: faker.datatype.number()
    })),
    reviews: [...Array(8)].map((_, index) => ({
      id: faker.datatype.uuid(),
      name: faker.name.findName(),
      avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
      comment: faker.lorem.lines(),
      rating: faker.datatype.number({ min: 1, max: 5 }),
      isPurchased: faker.datatype.boolean(),
      helpful: faker.datatype.number({ min: 1, max: 500 }),
      postedAt: faker.date.past()
    })),
    currency: 'dollar',
    status: sample(['sale', 'new', '', '']),
    inventoryType: sample(['in_stock', 'out_of_stock', 'low_stock']),
    sizes: PRODUCT_SIZE,
    sizesAvailability: PRODUCT_SIZE_AVAILABILITY_CLOTHES,
    available:
      setIndex % 3 === 0 ? faker.datatype.number({ min: 19, max: 100 }) : 2,
    description: PRODUCT_DESCRIPTION,
    sold: faker.datatype.number(),
    createdAt: faker.date.past(),
    type: 'clothes',
    category: PRODUCT_CATEGORY_CLOTHES[index],
    gender: PRODUCT_GENRE[index],
    editMode: true,
    me: 'boutique',
    owner: {
      photo: mockClothes(setIndex),
      name: 'Cheick Cisse',
      address_:
        'RE/MAX ELITE | 500 rue Notre-Dame Est, Victoriaville, Québec, G6P 4B5',
      email: 'cheickcisse@google.com',
      phone: '819-758-3293',
      id: '8864c717-587d-472a-929a-8e5f298024da-0',
      displayName: 'Jaydon Frankie',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg',
      phoneNumber: '+40 777666555',
      country: 'United States',
      address: '90210 Broadway Blvd',
      state: 'California',
      city: 'San Francisco',
      zipCode: '94116',
      about: '',
      role: 'admin',
      isPublic: true,
      companyName: 'Google'
    }
  };
});

const productsElectronics = [...Array(24)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba0-electronic-${setIndex}`,
    cover: mockElectronics(setIndex),
    images: [...Array(8)].map((_, index) => {
      const setIndex = index + 1;
      return mockElectronics(setIndex);
    }),
    name: PRODUCT_NAME_ELECTRICS[index],
    price: faker.datatype.number({ min: 100000, max: 500000, precision: 0.01 }),
    priceSale:
      setIndex % 3
        ? null
        : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    totalRating: faker.datatype.number({ min: 0, max: 5, precision: 0.1 }),
    totalReview: faker.datatype.number(),
    ratings: [...Array(5)].map((_, index) => ({
      name: `${index + 1} Star`,
      starCount: faker.datatype.number(),
      reviewCount: faker.datatype.number()
    })),
    reviews: [...Array(8)].map((_, index) => ({
      id: faker.datatype.uuid(),
      name: faker.name.findName(),
      avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
      comment: faker.lorem.lines(),
      rating: faker.datatype.number({ min: 1, max: 5 }),
      isPurchased: faker.datatype.boolean(),
      helpful: faker.datatype.number({ min: 1, max: 500 }),
      postedAt: faker.date.past()
    })),
    currency: 'dollar',
    /*colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,*/
    status: sample(['sale', 'new', '', '']),
    inventoryType: sample(['in_stock', 'out_of_stock', 'low_stock']),
    sizes: 0,
    sizesAvailability: PRODUCT_SIZE_AVAILABILITY_ELECTRICS,
    available: 1,
    description: PRODUCT_DESCRIPTION,
    sold: faker.datatype.number(),
    createdAt: faker.date.past(),
    type: 'electronics',
    category: PRODUCT_CATEGORY_ELECTRICS[index],
    allowBuy: false, // ALLOW_BUY[index],
    editMode: true,
    me: 'boutique',
    owner: {
      photo: mockElectronics(setIndex),
      name: 'Cheick Cisse',
      address_:
        'RE/MAX ELITE | 500 rue Notre-Dame Est, Victoriaville, Québec, G6P 4B5',
      email: 'cheickcisse@google.com',
      phone: '819-758-3293',
      id: '8864c717-587d-472a-929a-8e5f298024da-0',
      displayName: 'Jaydon Frankie',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg',
      phoneNumber: '+40 777666555',
      country: 'United States',
      address: '90210 Broadway Blvd',
      state: 'California',
      city: 'San Francisco',
      zipCode: '94116',
      about: '',
      role: 'admin',
      isPublic: true,
      companyName: 'Google'
    }
  };
});

const products = [...Array(4)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba0-${setIndex}`,
    cover: mockImo(setIndex),
    images: [...Array(8)].map((_, index) => {
      const setIndex = index + 1;
      return mockImo(setIndex);
    }),
    name: PRODUCT_NAME_IMMO[index],
    price: faker.datatype.number({
      min: 300000,
      max: 2000000,
      precision: 0.01
    }),
    priceSale:
      setIndex % 3
        ? null
        : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    totalRating: faker.datatype.number({ min: 0, max: 5, precision: 0.1 }),
    totalReview: faker.datatype.number(),
    ratings: [...Array(5)].map((_, index) => ({
      name: `${index + 1} Star`,
      starCount: faker.datatype.number(),
      reviewCount: faker.datatype.number()
    })),
    reviews: [...Array(8)].map((_, index) => ({
      id: faker.datatype.uuid(),
      name: faker.name.findName(),
      avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
      comment: faker.lorem.lines(),
      rating: faker.datatype.number({ min: 1, max: 5 }),
      isPurchased: faker.datatype.boolean(),
      helpful: faker.datatype.number({ min: 1, max: 500 }),
      postedAt: faker.date.past()
    })),
    colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,
    status: sample(['sale', 'new', '', '']),
    inventoryType: sample(['in_stock', 'out_of_stock', 'low_stock']),
    sizes: PRODUCT_SIZE,
    available:
      setIndex % 3 === 0 ? faker.datatype.number({ min: 19, max: 100 }) : 2,
    description: PRODUCT_DESCRIPTION,
    sold: faker.datatype.number(),
    createdAt: faker.date.past(),
    category: 'tshirt',
    gender: 'men',
    productAddress: PRODUCT_ADDRESS[index],
    bedroom: faker.datatype.number({ min: 1, max: 6 }),
    bathroom: faker.datatype.number({ min: 1, max: 6 }),
    buildYear: faker.datatype.number({ min: 1996, max: 2021 }),
    parking: faker.datatype.number({ min: 1, max: 2 }),
    sqft: faker.datatype.number({ min: 500, max: 4500 }),
    type: PRODUCT_TYPE[index],
    allowBuy: false, // ALLOW_BUY[index],
    editMode: true,
    owner: {
      photo: mockImo(setIndex),
      name: 'Cheick Cisse',
      address_:
        'RE/MAX ELITE | 500 rue Notre-Dame Est, Victoriaville, Québec, G6P 4B5',
      email: 'cheickcisse@google.com',
      phone: '819-758-3293',
      id: '8864c717-587d-472a-929a-8e5f298024da-0',
      displayName: 'Jaydon Frankie',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg',
      phoneNumber: '+40 777666555',
      country: 'United States',
      address: '90210 Broadway Blvd',
      state: 'California',
      city: 'San Francisco',
      zipCode: '94116',
      about: '',
      role: 'admin',
      isPublic: true,
      companyName: 'Google'
    },
    me: 'immobilier'
  };
});

const productscars = [...Array(10)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: `kl09mbb9-a411-1003-q7r7-5ab154rw1lx9-${setIndex}`,
    cover: mockCars(setIndex),
    images: [...Array(8)].map((_, index) => {
      const setIndex = index + 1;
      return mockCars(setIndex);
    }),
    name: PRODUCT_NAME_CARS[index],
    price: faker.datatype.number({
      min: 15000,
      max: 100000,
      precision: 0.01
    }),
    priceSale:
      setIndex % 3
        ? null
        : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    totalRating: faker.datatype.number({ min: 0, max: 5, precision: 0.1 }),
    totalReview: faker.datatype.number(),
    ratings: [...Array(5)].map((_, index) => ({
      name: `${index + 1} Star`,
      starCount: faker.datatype.number(),
      reviewCount: faker.datatype.number()
    })),
    reviews: [...Array(8)].map((_, index) => ({
      id: faker.datatype.uuid(),
      name: faker.name.findName(),
      avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
      comment: faker.lorem.lines(),
      rating: faker.datatype.number({ min: 1, max: 5 }),
      isPurchased: faker.datatype.boolean(),
      helpful: faker.datatype.number({ min: 1, max: 500 }),
      postedAt: faker.date.past()
    })),
    status: sample(['sale', 'new', '', '']),
    inventoryType: sample(['in_stock', 'out_of_stock', 'low_stock']),
    sizes: PRODUCT_SIZE,
    available:
      setIndex % 3 === 0 ? faker.datatype.number({ min: 19, max: 100 }) : 2,
    description: PRODUCT_DESCRIPTION_CARS,
    sold: faker.datatype.number(),
    createdAt: faker.date.past(),
    category: sample(['Accessories', 'Apparel', 'Shose']),
    gender: sample(['Men', 'Women', 'Kids']),
    productAddress: PRODUCT_ADDRESS_CARS[index],
    bedroom: faker.datatype.number({ min: 1, max: 6 }),
    bathroom: faker.datatype.number({ min: 1, max: 6 }),
    buildYear: faker.datatype.number({ min: 1996, max: 2021 }),
    parking: faker.datatype.number({ min: 1, max: 2 }),
    sqft: faker.datatype.number({ min: 500, max: 1500 }),
    colors: PRODUCT_COLOR_CARS[index],
    odometre: faker.datatype.number({ min: 600, max: 140000 }),
    transmission: PRODUCT_TRANSMISSION_CARS[index],
    condition: PRODUCT_CONDITION_CARS[index],
    brand: PRODUCT_BRAND_CARS[index],
    model: PRODUCT_MODEL_CARS[index],
    year: PRODUCT_YEAR_CARS[index],
    bodyType: PRODUCT_BODY_TYPE_CARS[index],
    body: PRODUCT_BODY_TYPE_CARS[index],
    fuel: PRODUCT_FUEL_CARS[index],
    gas: PRODUCT_FUEL_CARS[index],
    features: productFeature(index),
    colorsInterior: PRODUCT_INTERIOR_COLOR_CARS[index],
    engine: PRODUCT_ENGINE_CARS[index],
    colorsExterior: PRODUCT_EXTERIOR_COLOR_CARS[index],
    doors: PRODUCT_DOORS_CARS[index],
    passengers: PRODUCT_PASSENGERS_CARS[index],
    type: PRODUCT_TYPE_CARS[index],
    currency: 'dollar',
    owner: {
      photo: mockCars(setIndex),
      name: 'Cheick Cisse',
      address_:
        'RE/MAX ELITE | 500 rue Notre-Dame Est, Victoriaville, Québec, G6P 4B5',
      email: 'cheickcisse@google.com',
      phone: '819-758-3293',
      id: '8864c717-587d-472a-929a-8e5f298024da-0',
      displayName: 'Jaydon Frankie',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg',
      phoneNumber: '+40 777666555',
      country: 'United States',
      address: '90210 Broadway Blvd',
      state: 'California',
      city: 'San Francisco',
      zipCode: '94116',
      about: '',
      role: 'admin',
      isPublic: true,
      companyName: 'Google'
    },
    me: 'carsrent'
  };
});

const concatedProducts_ = productsclothes.concat(productscars);
const concatedProducts__ = concatedProducts_.concat(products);
const concatedProducts = concatedProducts__.concat(productsElectronics);

// ----------------------------------------------------------------------

mock.onGet('/api/accountposts/all').reply(200, { concatedProducts });

// ----------------------------------------------------------------------

mock.onGet('/api/accountposts').reply((config) => {
  try {
    const { index, step } = config.params;
    const maxLength = concatedProducts.length;
    const loadMore = index + step;

    const sortProducts = [...concatedProducts].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const results = sortProducts.slice(0, loadMore);

    return [200, { results, maxLength }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
