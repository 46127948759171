// routes
import {
    PATH_DOCS,
    PATH_DASHBOARD,
    PATH_PAGE,
    PATH_AUTH
  } from '../../routes/paths';
  // components
  import Label from '../../components/Label';
  import SvgIconStyle from '../../components/SvgIconStyle';
  
  // ----------------------------------------------------------------------
  
  const sidebarConfigNew = [
    {
      subheader: 'getting started',
      items: [
        { title: 'introduction', href: PATH_DOCS.introduction },
        { title: 'how it works?', href: PATH_DOCS.howItWorks },
      ]
    },
    {
      subheader: 'Community',
      items: [
        { title: 'code of conduct', href: PATH_DOCS.conduct },
        { title: 'about us', href: PATH_DOCS.aboutus },
      ]
    },
    {
      subheader: 'support',
      items: [
        { title: 'privacy', href: PATH_DOCS.privacy },
        { title: 'security', href: PATH_DOCS.security },
        { title: 'contact us', href: PATH_DOCS.contactus },
      ]
    }
  ];
  
  export default sidebarConfigNew;
  