import { sum, map, filter, uniqBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ES6+ example
import * as AWS from "aws-sdk";
import { awsConfig } from '../../config';
//import * as AWS from "@aws-sdk/client-dynamodb";
//import { S3Client, AbortMultipartUploadCommand } from "@aws-sdk/client-s3";


// ----------------------------------------------------------------------
const region_ = "eu-west-2";
const access_key_id_ = "AKIA3LSDR36ZVJZYM3U6";
const secret_access_key_ = "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";

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  productsCarsSell: [],
  product: null,
  sortBy: null,
  hasMore: true,
  index: 0,
  step: 11,
  filters: {
    gender: [],
    transmission: [],
    brand: [],
    category: 'All',
    colors: [],
    priceRange: '',
    odometerRange: '',
    rating: '',
    gas: [],
    year: [],
    body: [],
    condition: []
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null
  }
};

const slice = createSlice({
  name: 'motorcycles',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
      state.productsCarsSell = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.products = action.payload;
      state.productsCarsSell = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.transmission = action.payload.transmission;
      state.filters.brand = action.payload.brand;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.odometerRange = action.payload.odometerRange;
      state.filters.rating = action.payload.rating;
      state.filters.gas = action.payload.gas;
      state.filters.year = action.payload.year;
      state.filters.body = action.payload.body;
      state.filters.condition = action.payload.condition;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = filter(
        state.checkout.cart,
        (item) => item.id !== action.payload
      );

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  getMorePosts
} = slice.actions;

// ----------------------------------------------------------------------

export function getMotorcycles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: region_,
        accessKeyId: access_key_id_,
        secretAccessKey: secret_access_key_
      });
      
      const client = new AWS.DynamoDB();
      const param = {
        TableName: "motorcycles"
      };
      const result = await client.scan(param).promise();
      var myMap = {};
      const dataArray = new Array;
      for (const item of result.Items) {
        const data = AWS.DynamoDB.Converter.unmarshall(item);
        myMap[data.id] = data;
        dataArray.push(data);
      }
      localStorage.setItem('motorcycles', JSON.stringify(myMap));

      dispatch(slice.actions.getProductsSuccess(dataArray));
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMotorcyclesMock() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/motos');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      console.log(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMotorcycleMock(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/motos/moto', {
        params: { id: name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function onDeleteItem(id) {
  try {
    AWS.config.update({
      region: awsConfig.region,
      accessKeyId: awsConfig.accessKeyId,
      secretAccessKey: awsConfig.secretAccessKey
    });

    const clientDynamo = new AWS.DynamoDB();
    const param = {
      TableName: 'motorcycles',
      Key: {
        id : {
          'S': id
        }
      }
    };
    await clientDynamo.deleteItem(param).promise();
    
    // Update owner
    const owner = JSON.parse(localStorage.getItem('owner'));
    const owner_param = {
      TableName: "owners",
      Key: {
        id : {
          'S': owner.id
        }
      }
    };
    const owner_from_db_raw = await clientDynamo.getItem(owner_param).promise();
    var owner_from_db = AWS.DynamoDB.Converter.unmarshall(owner_from_db_raw.Item);
    const index = owner_from_db['posts'].values.indexOf(id);
    owner_from_db['posts'].values.splice(index, 1);
    localStorage.setItem('owner', JSON.stringify(owner_from_db));

    const update_owner_param = {
      TableName: 'owners',
      Key: { 
        id : {
          'S': owner.id
        } 
      },
      UpdateExpression: 'set posts = :x',
      ExpressionAttributeValues: {
        ':x' : {
          'SS': owner_from_db['posts'].values
        }
      }
    };
    await clientDynamo.updateItem(update_owner_param).promise();

  } catch (error) {
    console.log(error);
  }
}

// ----------------------------------------------------------------------

export function getMotorcycle(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const cached_motos = JSON.parse(localStorage.getItem('motorcycles'));
      const data = cached_motos[id];
    
      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // ratings
      const ratings = [];
      const ratingsHrefs = data['ratings']
      for (const rating of ratingsHrefs) {
        const paramRatings = {
          TableName: "ratings",
          Key: {
            id : {
              'S': rating
            }
          }
        };
        const resultRating = await client.getItem(paramRatings).promise();
        const dataRating = AWS.DynamoDB.Converter.unmarshall(resultRating.Item);
        ratings.push(dataRating);
      }
      data['ratings'] = ratings;

      // reviews
      const reviews = [];
      const reviewsHrefs = data['reviews']
      for (const review of reviewsHrefs) {
        const paramReview = {
          TableName: "reviews",
          Key: {
            id : {
              'S': review
            }
          }
        };
        const resultReview = await client.getItem(paramReview).promise();
        const dataReview = AWS.DynamoDB.Converter.unmarshall(resultReview.Item);
        ratings.push(dataReview);
      }
      data['reviews'] = reviews;

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMotorcycleByIdEdit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const store_accessories = JSON.parse(localStorage.getItem('motorcycles_edit'));
      var store_accessorie;
      for (const accessorie of store_accessories) {
        const accessorie_ = AWS.DynamoDB.Converter.unmarshall(accessorie);
        if (accessorie_.id === id) {
          store_accessorie = accessorie_;
          break;
        }
      }
      const data = store_accessorie;

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // ratings
      data['ratings'] = data['ratings'].values;

      // reviews
      data['reviews'] = data['reviews'].values;

      // images
      data['images'] = data['images'].values

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPostsInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/motos/step', {
        params: { index, step }
      });
      const results = response.data.results.length;
      const { maxLength } = response.data;

      dispatch(slice.actions.getPostsInitial(response.data.results));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
