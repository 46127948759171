// routes
import {
    PATH_DOCS,
    PATH_DASHBOARD,
    PATH_PAGE,
    PATH_AUTH
  } from '../../routes/paths';
  // components
  import Label from '../../components/Label';
  import SvgIconStyle from '../../components/SvgIconStyle';
  
  // ----------------------------------------------------------------------
    
  const sidebarConfigNewFr = [
    {
      subheader: 'comment commencer',
      items: [
        { title: 'introduction', href: PATH_DOCS.introduction },
        { title: 'comment ça fonctionne?', href: PATH_DOCS.howItWorks },
      ]
    },
    {
      subheader: 'Communauté',
      items: [
        { title: 'code de conduite', href: PATH_DOCS.conduct },
        { title: 'à propos de nous', href: PATH_DOCS.aboutus },
      ]
    },
    {
      subheader: 'support',
      items: [
        { title: 'confidentialité', href: PATH_DOCS.privacy },
        { title: 'sécurité', href: PATH_DOCS.security },
        { title: 'nous contacter', href: PATH_DOCS.contactus },
      ]
    }
  ];
  
  export default sidebarConfigNewFr;
  