import { sum, map, filter, uniqBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import * as AWS from "aws-sdk";
import { awsConfig } from '../../config';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'all',
    colors: [],
    priceRange: '',
    rating: '',
    size: 'all'
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null
  }
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
      state.filters.size = action.payload.size;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = filter(
        state.checkout.cart,
        (item) => item.id !== action.payload
      );

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts
} = slice.actions;

// ----------------------------------------------------------------------
// Products

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function onDeleteItem(id, table_name) {
  try {
    AWS.config.update({
      region: awsConfig.region,
      accessKeyId: awsConfig.accessKeyId,
      secretAccessKey: awsConfig.secretAccessKey
    });

    const clientDynamo = new AWS.DynamoDB();
    const param = {
      TableName: table_name,
      Key: {
        id : {
          'S': id
        }
      }
    };
    await clientDynamo.deleteItem(param).promise();
    
    // Update owner
    const owner = JSON.parse(localStorage.getItem('owner'));
    const owner_param = {
      TableName: "owners",
      Key: {
        id : {
          'S': owner.id
        }
      }
    };
    const owner_from_db_raw = await clientDynamo.getItem(owner_param).promise();
    var owner_from_db = AWS.DynamoDB.Converter.unmarshall(owner_from_db_raw.Item);
    const index = owner_from_db['posts'].values.indexOf(id);
    owner_from_db['posts'].values.splice(index, 1);
    localStorage.setItem('owner', JSON.stringify(owner_from_db));

    const update_owner_param = {
      TableName: 'owners',
      Key: { 
        id : {
          'S': owner.id
        } 
      },
      UpdateExpression: 'set posts = :x',
      ExpressionAttributeValues: {
        ':x' : {
          'SS': owner_from_db['posts'].values
        }
      }
    };
    await clientDynamo.updateItem(update_owner_param).promise();

  } catch (error) {
    console.log(error);
  }
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/product', {
        params: { name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/product/id', {
        params: { id }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// Shoes

export function getShoesMock() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/shoes');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getShoes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
        dynamoDbCrc32: false
      });
      
      const client = new AWS.DynamoDB();
      const param = {
        TableName: "store_shoes"
      };

      const result = await client.scan(param).promise();
      const dataArray = new Array;
      var myMap = {};
      for (const item of result.Items) {
        const data = AWS.DynamoDB.Converter.unmarshall(item);
        myMap[data.id] = data;
        dataArray.push(data);
      }
      localStorage.setItem('store_shoes', JSON.stringify(myMap));

      dispatch(slice.actions.getProductsSuccess(dataArray));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getShoeById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const cached = JSON.parse(localStorage.getItem('store_shoes'));
      const data = cached[id];

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      const ratings = [];
      const ratingsHrefs = data['ratings'];
      for (const rating of ratingsHrefs) {
        if (rating !== '') {
          const paramRatings = {
            TableName: "ratings",
            Key: {
              id : {
                'S': rating
              }
            }
          };
          const resultRating = await client.getItem(paramRatings).promise();
          const dataRating = AWS.DynamoDB.Converter.unmarshall(resultRating.Item);
          ratings.push(dataRating);
        }
      }

      if (ratings.length > 0) {
        data['ratings'] = ratings;
      }

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getShoeByIdEdit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const store_shoes = JSON.parse(localStorage.getItem('store_shoes_edit'));
      var store_shoe;
      for (const shoe of store_shoes) {
        const shoe_ = AWS.DynamoDB.Converter.unmarshall(shoe);
        if (shoe_.id === id) {
          store_shoe = shoe_;
          break;
        }
      }
      const data = store_shoe;

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      data['ratings'] = data['ratings'].values;

      // reviews
      data['reviews'] = data['reviews'].values;

      // images
      data['images'] = data['images'].values

      // Sizes
      data['sizes'] = data['sizes'].values

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getShoeMock(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.get('/api/shoes/shoe/id', {
        params: { id: name }
      });
      console.log(response.data.product);
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// Objects

export function getObjectsMock() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/objects');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getObjects() {
  return async (dispatch) => {
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
        dynamoDbCrc32: false
      });
      
      const client = new AWS.DynamoDB();
      const param = {
        TableName: "store_objects"
      };

      const result = await client.scan(param).promise();
      const dataArray = new Array;
      var myMap = {};
      for (const item of result.Items) {
        const data = AWS.DynamoDB.Converter.unmarshall(item);
        myMap[data.id] = data;
        dataArray.push(data);
      }
      localStorage.setItem('store_objects', JSON.stringify(myMap));

      dispatch(slice.actions.getProductsSuccess(dataArray));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getObjectsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const cached = JSON.parse(localStorage.getItem('store_objects'));
      const data = cached[id];

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      const ratings = [];
      const ratingsHrefs = data['ratings'];
      for (const rating of ratingsHrefs) {
        if (rating !== '') {
          const paramRatings = {
            TableName: "ratings",
            Key: {
              id : {
                'S': rating
              }
            }
          };
          const resultRating = await client.getItem(paramRatings).promise();
          const dataRating = AWS.DynamoDB.Converter.unmarshall(resultRating.Item);
          ratings.push(dataRating);
        }
      }

      if (ratings.length > 0) {
        data['ratings'] = ratings;
      }

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getObjectsByIdEdit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const store_shoes = JSON.parse(localStorage.getItem('store_objects_edit'));
      var store_shoe;
      for (const shoe of store_shoes) {
        const shoe_ = AWS.DynamoDB.Converter.unmarshall(shoe);
        if (shoe_.id === id) {
          store_shoe = shoe_;
          break;
        }
      }
      const data = store_shoe;

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      data['ratings'] = data['ratings'].values;

      // reviews
      data['reviews'] = data['reviews'].values;

      // images
      data['images'] = data['images'].values

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getObject(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/object', {
        params: { name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getObjectMock(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/objects/object/id', {
        params: { id: name }
      });
      console.log(response.data.product);
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// Clothes

export function getClothsMock() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/cloths');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCloths() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
        dynamoDbCrc32: false
      });
      
      const client = new AWS.DynamoDB();
      const param = {
        TableName: "store_clothes"
      };

      const result = await client.scan(param).promise();
      console.log(result);
      const dataArray = new Array;
      var myMap = {};
      for (const item of result.Items) {
        const data = AWS.DynamoDB.Converter.unmarshall(item);
        console.log(data);
        myMap[data.id] = data;
        dataArray.push(data);
      }
      localStorage.setItem('store_clothes', JSON.stringify(myMap));

      dispatch(slice.actions.getProductsSuccess(dataArray));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClothsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const cached = JSON.parse(localStorage.getItem('store_clothes'));
      const data = cached[id];

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      const ratings = [];
      const ratingsHrefs = data['ratings'];
      for (const rating of ratingsHrefs) {
        if (rating !== '') {
          const paramRatings = {
            TableName: "ratings",
            Key: {
              id : {
                'S': rating
              }
            }
          };
          const resultRating = await client.getItem(paramRatings).promise();
          const dataRating = AWS.DynamoDB.Converter.unmarshall(resultRating.Item);
          ratings.push(dataRating);
        }
      }

      if (ratings.length > 0) {
        data['ratings'] = ratings;
      }

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClothsByIdEdit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const store_clothes = JSON.parse(localStorage.getItem('store_clothes_edit'));
      var store_accessorie;
      for (const accessorie of store_clothes) {
        const accessorie_ = AWS.DynamoDB.Converter.unmarshall(accessorie);
        if (accessorie_.id === id) {
          store_accessorie = accessorie_;
          break;
        }
      }
      const data = store_accessorie;

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      data['ratings'] = data['ratings'].values;

      // reviews
      data['reviews'] = data['reviews'].values;

      // images
      data['images'] = data['images'].values;

      data['sizes'] = data['sizes'].values;

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCloth(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/cloths/cloth', {
        params: { name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClothMock(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/cloths/cloth/id', {
        params: { id: name }
      });
      console.log(response.data.product);
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// Accessories

export function getAccessoriesMocks() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/accessories');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccessories() {
  return async (dispatch) => {
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
        dynamoDbCrc32: false
      });
      
      const client = new AWS.DynamoDB();
      const param = {
        TableName: "store_accessories"
      };

      const result = await client.scan(param).promise();
      const dataArray = new Array;
      var myMap = {};
      for (const item of result.Items) {
        const data = AWS.DynamoDB.Converter.unmarshall(item);
        myMap[data.id] = data;
        dataArray.push(data);
      }
      localStorage.setItem('store_accessories', JSON.stringify(myMap));

      dispatch(slice.actions.getProductsSuccess(dataArray));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccessoriesById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const cached = JSON.parse(localStorage.getItem('store_accessories'));
      const data = cached[id];

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      const ratings = [];
      const ratingsHrefs = data['ratings'];
      for (const rating of ratingsHrefs) {
        if (rating !== '') {
          const paramRatings = {
            TableName: "ratings",
            Key: {
              id : {
                'S': rating
              }
            }
          };
          const resultRating = await client.getItem(paramRatings).promise();
          const dataRating = AWS.DynamoDB.Converter.unmarshall(resultRating.Item);
          ratings.push(dataRating);
        }
      }

      if (ratings.length > 0) {
        data['ratings'] = ratings;
      }

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccessoriesByIdEdit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const store_accessories = JSON.parse(localStorage.getItem('store_accessories_edit'));
      var store_accessorie;
      for (const accessorie of store_accessories) {
        const accessorie_ = AWS.DynamoDB.Converter.unmarshall(accessorie);
        if (accessorie_.id === id) {
          store_accessorie = accessorie_;
          break;
        }
      }
      const data = store_accessorie;

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      data['ratings'] = data['ratings'].values;

      // reviews
      data['reviews'] = data['reviews'].values;

      // images
      data['images'] = data['images'].values

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccessorie(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/accessories/accessorie', {
        params: { name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAccessoriesMock(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/accessories/accessorie/id', {
        params: { id: name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// Electronics
// /api/electronics/electronic/id

export function getElectronics() {
  return async (dispatch) => {
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
        dynamoDbCrc32: false
      });
      
      const client = new AWS.DynamoDB();
      const param = {
        TableName: "store_electronics"
      };

      const result = await client.scan(param).promise();
      const dataArray = new Array;
      var myMap = {};
      for (const item of result.Items) {
        const data = AWS.DynamoDB.Converter.unmarshall(item);
        myMap[data.id] = data;
        dataArray.push(data);
      }
      localStorage.setItem('store_electronics', JSON.stringify(myMap));

      dispatch(slice.actions.getProductsSuccess(dataArray));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getElectronicsMocks() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/electronics');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getElectronicsById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const cached = JSON.parse(localStorage.getItem('store_electronics'));
      const data = cached[id];

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      const ratings = [];
      const ratingsHrefs = data['ratings'];
      for (const rating of ratingsHrefs) {
        if (rating !== '') {
          const paramRatings = {
            TableName: "ratings",
            Key: {
              id : {
                'S': rating
              }
            }
          };
          const resultRating = await client.getItem(paramRatings).promise();
          const dataRating = AWS.DynamoDB.Converter.unmarshall(resultRating.Item);
          ratings.push(dataRating);
        }
      }

      if (ratings.length > 0) {
        data['ratings'] = ratings;
      }

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.log("error");
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getElectronicsByIdEdit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const store_shoes = JSON.parse(localStorage.getItem('store_electronics_edit'));
      var store_shoe;
      for (const shoe of store_shoes) {
        const shoe_ = AWS.DynamoDB.Converter.unmarshall(shoe);
        if (shoe_.id === id) {
          store_shoe = shoe_;
          break;
        }
      }
      const data = store_shoe;

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      data['ratings'] = data['ratings'].values;

      // reviews
      data['reviews'] = data['reviews'].values;

      // images
      data['images'] = data['images'].values

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getElectronic(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/electronic', {
        params: { name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getElectronicMock(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/electronics/electronic/id', {
        params: { id: name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// Beauty

export function getBeauty() {
  return async (dispatch) => {
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
        dynamoDbCrc32: false
      });
      
      const client = new AWS.DynamoDB();
      const param = {
        TableName: "store_beauty"
      };

      const result = await client.scan(param).promise();
      const dataArray = new Array;
      var myMap = {};
      for (const item of result.Items) {
        const data = AWS.DynamoDB.Converter.unmarshall(item);
        myMap[data.id] = data;
        dataArray.push(data);
      }
      localStorage.setItem('store_beauty', JSON.stringify(myMap));

      dispatch(slice.actions.getProductsSuccess(dataArray));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBeautyById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const cached = JSON.parse(localStorage.getItem('store_beauty'));
      const data = cached[id];

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      const ratings = [];
      const ratingsHrefs = data['ratings'];
      for (const rating of ratingsHrefs) {
        if (rating !== '') {
          const paramRatings = {
            TableName: "ratings",
            Key: {
              id : {
                'S': rating
              }
            }
          };
          const resultRating = await client.getItem(paramRatings).promise();
          const dataRating = AWS.DynamoDB.Converter.unmarshall(resultRating.Item);
          ratings.push(dataRating);
        }
      }

      if (ratings.length > 0) {
        data['ratings'] = ratings;
      }

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBeautyByIdEdit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey
      });
      const client = new AWS.DynamoDB();

      const store_beauty = JSON.parse(localStorage.getItem('store_beauty_edit'));
      var store_accessorie;
      for (const accessorie of store_beauty) {
        const accessorie_ = AWS.DynamoDB.Converter.unmarshall(accessorie);
        if (accessorie_.id === id) {
          store_accessorie = accessorie_;
          break;
        }
      }
      const data = store_accessorie;

      // owner
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': data['owner']
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);
      const owner = dataOwners;
      data['owner'] = owner;

      // sizesAvailability
      const paramSizesAvailability = {
        TableName: "size_availabilities",
        Key: {
          id : {
            'S': data['sizesAvailability']
          }
        }
      };
      const resultSizesAvailability = await client.getItem(paramSizesAvailability).promise();
      const dataSizesAvailability = AWS.DynamoDB.Converter.unmarshall(resultSizesAvailability.Item);
      data['sizesAvailability'] = dataSizesAvailability;

      // ratings
      data['ratings'] = data['ratings'].values;

      // reviews
      data['reviews'] = data['reviews'].values;

      // images
      data['images'] = data['images'].values

      dispatch(slice.actions.getProductSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBeautyMock(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/accessories/accessorie/id', {
        params: { id: name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
