import faker from 'faker';
import { sample } from 'lodash';
import { paramCase } from 'change-case';
// utils
import mock from '../utils/mock';
import { mockImo } from '../utils/mockImages';

// ----------------------------------------------------------------------

const PRODUCT_NAME = [
  'House for sale',
  'Quatruplex for sale',
  'Condo for sale',
  'Condo for sale',
  'Spanish house for sale',
  'Newly build house for sale',
  'Condo for sale',
  'House for sale',
  'House for sale',
  'House for sale',
  'Quatruplex for sale',
  'Newly build house for sale',
  'Quatruplex for sale',
  'Triplex for sale',
  'Quatruplex for sale',
  'Condo for sale',
  'Newly build house for sale',
  'House for sale',
  'NikeCourt Royale',
  'House for sale',
  'Newly build house for sale',
  'House for sale',
  'Newly build house for sale',
  'House for sale'
];

const PRODUCT_ADDRESS = [
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 1',
  '391, Rue Duhamel, Montréal (LaSalle)',
  'Avenue George-V, apt. 501, Montréal (Lachine), Neighbourhood East',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 2',
  '190, Rue Murray, apt. 1006, Montréal (Le Sud-Ouest), Neighbourhood Griffintown',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 3',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 4',
  '190, Rue Murray, apt. 1006, Montréal (Le Sud-Ouest), Neighbourhood Griffintown',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 5',
  '391, Rue Duhamel, Montréal (LaSalle)',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 6',
  '391, Rue Duhamel, Montréal (LaSalle)',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  'Avenue George-V, apt. 501, Montréal (Lachine), Neighbourhood East',
  '391, Rue Duhamel, Montréal (LaSalle)',
  '190, Rue Murray, apt. 1006, Montréal (Le Sud-Ouest), Neighbourhood Griffintown',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 7',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  '190, Rue Murray, apt. 1006, Montréal (Le Sud-Ouest), Neighbourhood Griffintown',
  '175, Rue de la Montagne, apt. 906, Montréal (Le Sud-Ouest), Neighbourhood Griffintown 8',
  '6801, boulevard des Roseraies, apt. 406, Montréal (Anjou)',
  'Avenue George-V, apt. 501, Montréal (Lachine), Neighbourhood East'
];

const PRODUCT_TYPE = [
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell'
];

const PRODUCT_COLOR = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107'
];

const PRODUCT_DESCRIPTION = `
<p><strong><small> DESCRIPTION</small></strong></p>
<p>Belle maison possédant 4 chambres à coucher et un grand terrain intime bordé de haies avec arbres mature. Secteur tranquille près de tous les services. La véranda 3 saisons est l'endroit idéal pour recevoir. Un accès sur le côté de la résidence vous permet d'y stationner un motorisé ou un bateau. Sous sol aménagé avec poêle au bois. C'est à voir!.
`;

const PRODUCT_SIZE = [
  '6',
  '7',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '13'
];

const products = [...Array(24)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba0-${setIndex}`,
    cover: mockImo(setIndex),
    images: [...Array(8)].map((_, index) => {
      const setIndex = index + 1;
      return mockImo(setIndex);
    }),
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({
      min: 300000000,
      max: 2000000000,
      precision: 0.01
    }),
    priceSale:
      setIndex % 3
        ? null
        : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    totalRating: faker.datatype.number({ min: 0, max: 5, precision: 0.1 }),
    totalReview: faker.datatype.number(),
    ratings: [...Array(5)].map((_, index) => ({
      name: `${index + 1} Star`,
      starCount: faker.datatype.number(),
      reviewCount: faker.datatype.number()
    })),
    reviews: [...Array(8)].map((_, index) => ({
      id: faker.datatype.uuid(),
      name: faker.name.findName(),
      avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
      comment: faker.lorem.lines(),
      rating: faker.datatype.number({ min: 1, max: 5 }),
      isPurchased: faker.datatype.boolean(),
      helpful: faker.datatype.number({ min: 1, max: 500 }),
      postedAt: faker.date.past()
    })),
    colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,
    status: sample(['sale', 'new', '', '']),
    inventoryType: sample(['in_stock', 'out_of_stock', 'low_stock']),
    sizes: PRODUCT_SIZE,
    available:
      setIndex % 3 === 0 ? faker.datatype.number({ min: 19, max: 100 }) : 2,
    description: PRODUCT_DESCRIPTION,
    sold: faker.datatype.number(),
    createdAt: faker.date.past(),
    category: sample(['Accessories', 'Apparel', 'Shose']),
    gender: sample(['Men', 'Women', 'Kids']),
    productAddress: PRODUCT_ADDRESS[index],
    bedroom: faker.datatype.number({ min: 1, max: 6 }),
    bathroom: faker.datatype.number({ min: 1, max: 6 }),
    buildYear: faker.datatype.number({ min: 1996, max: 2021 }),
    parking: faker.datatype.number({ min: 1, max: 2 }),
    sqft: faker.datatype.number({ min: 500, max: 4500 }),
    type: PRODUCT_TYPE[index],
    currency: 'dollar',
    owner: {
      photo: mockImo(setIndex),
      name: 'Cheick Cisse',
      address_:
        'RE/MAX ELITE | 500 rue Notre-Dame Est, Victoriaville, Québec, G6P 4B5',
      email: 'cheickcisse@google.com',
      phone: '819-758-3293',
      id: '8864c717-587d-472a-929a-8e5f298024da-0',
      displayName: 'Jaydon Frankie',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg',
      phoneNumber: '+40 777666555',
      country: 'United States',
      address: '90210 Broadway Blvd',
      state: 'California',
      city: 'San Francisco',
      zipCode: '94116',
      about: '',
      role: 'admin',
      isPublic: true,
      companyName: 'Google'
    },
    me: 'immobilier'
  };
});

// ----------------------------------------------------------------------

mock.onGet('/api/immobiliers').reply(200, { products });

// ----------------------------------------------------------------------

mock.onGet('/api/immobiliers/step').reply((config) => {
  try {
    const { index, step } = config.params;
    const maxLength = products.length;
    const loadMore = index + step;

    const sortProducts = [...products].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const results = sortProducts.slice(0, loadMore);

    return [200, { results, maxLength }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onGet('/api/immobiliers/immobilier').reply((config) => {
  try {
    const { id } = config.params;
    const product = products.find((_product) => paramCase(_product.id) === id);

    if (!product) {
      return [404, { message: 'product not found' }];
    }

    return [200, { product }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
