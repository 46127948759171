import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';

// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  List,
  Box,
  Drawer,
  Hidden,
  ListSubheader,
  Typography,
  Button
} from '@material-ui/core';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
//
import NavItem from './SidebarItem';
import menuListsEn from './SidebarConfigNew';
import menuListsFr from './SidebarConfigNewFr';
import fullMenuLists from './SidebarConfig';
// hooks
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.primary.main, 0.08)
      : theme.palette.primary.lighter
}));

// ----------------------------------------------------------------------

DocsSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DocsSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user, isAuthenticated } = useAuth();
  const { allLang, currentLang, onChangeLang } = useLocales();
  const newFeature = false;
  const menuLists = currentLang.value === 'en' ? menuListsEn : menuListsFr;

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ p: 1, pb: 5 }}>
        <Hidden mdUp>
          <Box sx={{ px: 2, py: 3 }}>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>

        {menuLists.map((list) => (
          <List
            disablePadding
            key={list.subheader}
            subheader={
              <ListSubheader
                disableSticky
                disableGutters
                sx={{
                  pl: 2,
                  height: 44,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.primary',
                  typography: 'overline'
                }}
              >
                {list.subheader}
              </ListSubheader>
            }
            sx={{
              '&:not(:last-of-type)': { mb: 5 }
            }}
          >
            {list.items.map((item) => (
              <NavItem key={item.title} link={item} />
            ))}
          </List>
        ))}
      </Box>
      {newFeature && (
        <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
          <DocStyle>
            <Box
              component="img"
              alt="doc"
              src="/static/icons/ic_doc.svg"
              sx={{ width: 36, height: 36, mb: 2 }}
            />
            {isAuthenticated && (
              <Typography
                gutterBottom
                variant="subtitle1"
                sx={{ color: 'grey.800' }}
              >
                Hi, {user.displayName}
              </Typography>
            )}
            <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
              Need help?
              <br /> Please check our docs
            </Typography>
            <Button
              fullWidth
              to={PATH_DOCS.root}
              variant="contained"
              component={RouterLink}
            >
              Documentation
            </Button>
          </DocStyle>
        </Box>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden mdUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          <Box sx={{ pt: 10, height: '100%' }}>{renderContent}</Box>
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
