// routes
import { PATH_DASHBOARD, PATH_PAGE, PATH_AUTH } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

import useLocales from '../../hooks/useLocales';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  map: getIcon('ic_map'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  page: getIcon('ic_page'),
  user: getIcon('ic_user'),
  copy: getIcon('ic_copy'),
  error: getIcon('ic_error'),
  charts: getIcon('ic_charts'),
  editor: getIcon('ic_editor'),
  upload: getIcon('ic_upload'),
  animate: getIcon('ic_animate'),
  calendar: getIcon('ic_calendar'),
  elements: getIcon('ic_elements'),
  carousel: getIcon('ic_carousel'),
  language: getIcon('ic_language'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  components: getIcon('ic_components'),
  authenticator: getIcon('ic_authenticator'),
  immobilier: getIcon('home-outline'),
  home: getIcon('home'),
  car: getIcon('car'),
  shoppingCart: getIcon('shopping-cart'),
  blog2: getIcon('blog'),
  headhunting: getIcon('headhunting'),
  education: getIcon('open-book'),
  customerSupport: getIcon('customer-support'),
  moto: getIcon('moto'),
  cropAlt: getIcon('crop-alt')
};

const sidebarConfigFr = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'Immobiliers',
        href: PATH_DASHBOARD.general.immobilier,
        icon: ICONS.home,
        items: [
          {
            title: 'Achat',
            href: PATH_DASHBOARD.general.immobilier
          },
          {
            title: 'Location',
            href: PATH_DASHBOARD.general.immobilierRent
          }
        ]
      },
      {
        title: 'Terrains',
        href: PATH_DASHBOARD.general.lands,
        icon: ICONS.cropAlt
      },
      {
        title: 'Voitures',
        href: PATH_DASHBOARD.general.cars,
        icon: ICONS.car,
        items: [
          {
            title: 'Achat',
            href: PATH_DASHBOARD.general.cars
          },
          {
            title: 'Location',
            href: PATH_DASHBOARD.general.carsRent
          }
        ]
      },
      {
        title: 'Motos',
        href: PATH_DASHBOARD.general.motorcycles,
        icon: ICONS.moto
      },
      /*{
        title: 'Boutique',
        href: PATH_DASHBOARD.general.cloths,
        icon: ICONS.shoppingCart,
        items: [
          {
            title: 'Vêtements',
            href: PATH_DASHBOARD.general.cloths
          },
          {
            title: 'Chaussures',
            href: PATH_DASHBOARD.general.shoes
          },
          {
            title: 'Beauté / Soins Personnels',
            href: PATH_DASHBOARD.shop.beauty
          },
          {
            title: 'accessoires',
            href: PATH_DASHBOARD.general.accessories
          },
          {
            title: 'Objets',
            href: PATH_DASHBOARD.general.objects
          },
          {
            title: 'Électroniques',
            href: PATH_DASHBOARD.general.electronics
          }
        ]
      },*/
      {
        title: 'Emplois',
        href: PATH_DASHBOARD.general.employments,
        icon: ICONS.headhunting
      },
      {
        title: 'Evenements',
        href: PATH_DASHBOARD.general.evenments,
        icon: ICONS.calendar
      },
      {
        title: 'Services',
        href: PATH_DASHBOARD.general.services,
        icon: ICONS.customerSupport
      }
    ]
  },
  {
    subheader: 'Compte',
    icon: ICONS.blog2,
    href: PATH_DASHBOARD.blog.root,
    items: [
      {
        title: 'Mes Publications',
        href: PATH_DASHBOARD.account.allPosts
      },
      {
        title: 'Analyse',
        href: PATH_DASHBOARD.account.analytics
      },
      {
        title: 'Nouvelle Publication',
        href: PATH_DASHBOARD.account.newImmobilier,
        items: [
          {
            title: 'Immobilier',
            href: PATH_DASHBOARD.account.newImmobilier
          },
          {
            title: 'Terrain',
            href: PATH_DASHBOARD.account.newLand
          },
          {
            title: 'Voiture',
            href: PATH_DASHBOARD.account.newCar
          },
          {
            title: 'Moto',
            href: PATH_DASHBOARD.account.newMotorcycle
          },
          /*{
            title: 'Boutique',
            href: PATH_DASHBOARD.account.newBoutique
          }*/
        ]
      },
      {
        title: 'Paramètres',
        href: PATH_DASHBOARD.user.account
      }
    ]
  }
];

export default sidebarConfigFr;
