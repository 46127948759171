import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestore
} from 'react-redux-firebase';
// redux
import { login, register, logout } from '../redux/slices/authJwt';
import * as AWS from "aws-sdk";
import { awsConfig } from '../config';
import { useHistory } from 'react-router-dom';
import { PATH_DASHBOARD } from '../routes/paths';
// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth(method = /*'jwt'*/ 'firebase') {
  const history = useHistory();
  // Firebase Auth
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { auth, profile } = useSelector((state) => state.firebase);

  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );

  const emailFromPhone = (phone) => {
    return phone+"@nested.com";
  }

  // JWT Auth
  if (method === 'jwt') {
    return {
      method: 'jwt',
      user,
      isLoading,
      isAuthenticated,

      login: ({ email, password }) =>
        dispatch(
          login({
            email,
            password
          })
        ),

      register: ({ email, password, firstName, lastName }) =>
        dispatch(
          register({
            email,
            password,
            firstName,
            lastName
          })
        ),

      logout: () => dispatch(logout()),

      resetPassword: () => {},

      updateProfile: () => {}
    };
  }

  const owner = JSON.parse(localStorage.getItem('owner'));

  const gogo = () => {
    history.push(PATH_DASHBOARD.root);
  }

  const RESET_ACTION = {
    type: 'SIGNOUT_REQUEST'
  };

  // Firebase Auth
  return {
    method: 'firebase',
    user: {
      id: (owner && owner.id) || auth.uid || '',
      displayName: (owner && owner.displayName) || auth.displayName || '',
      email: (owner && owner.email) || auth.email || '',
      photoURL: (owner && owner.photoURL) || auth.photoURL || '',
      phoneNumber: (owner && owner.phoneNumber) || auth.phoneNumber || '',
      country: (owner && owner.country) || '',
      address: (owner && owner.address) || '',
      state: (owner && owner.state) || '',
      city: (owner && owner.city) || '',
      zipCode: (owner && owner.zipCode) || '',
      about: (owner && owner.about) || '',
      role: (owner && owner.role) || '',
      isPublic: (owner && owner.isPublic) || false,
      companyName: (owner && owner.companyName) || '',
      addresses: (owner && owner.addresses) || []
    },
    isLoading: !isLoaded(auth),
    isAuthenticated: !isEmpty(auth),

    login: ({ email, password, phone }) =>
      firebase
        .login({
          email: email ? email : emailFromPhone(phone),
          password
        })
        .then((res) => {
          localStorage.setItem('user_auth', JSON.stringify(res.user.user));
          AWS.config.update({
            region: awsConfig.region,
            accessKeyId: awsConfig.accessKeyId,
            secretAccessKey: awsConfig.secretAccessKey
          });
          const id = res.user.user.uid;
          const client = new AWS.DynamoDB();
          const param = {
            TableName: "owners",
            Key: {
              id : {
                'S': id
              }
            }
          };

          return client.getItem(param).promise();
        })
      .then((result) => {
        const data = AWS.DynamoDB.Converter.unmarshall(result.Item);
        // isPublic
        data['isPublic'] = 'true';
        // Posts
        data['posts'] = data['posts'].values
        localStorage.setItem('owner', JSON.stringify(data));
      }),
    updatePassword: (email, oldPassword, newPassword, s, f) => {
      const creds = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
      firebase.auth().currentUser.reauthenticateWithCredential(creds)
      .then((res) => {
        firebase.auth().currentUser.updatePassword(newPassword)
        .then((res) => {
          s();
        })
      })
      .catch(error => {
        if (error.code == 'auth/too-many-requests') {
          firebase.logout();
        }
        console.log(error);
        f();
      });
    },
    resetPassword: (email, oldPassword, newPassword, s, f) => {
      firebase.auth().currentUser.updatePassword(newPassword)
      .then((res) => {
        s();
      })
      .catch(error => {
        if (error.code == 'auth/too-many-requests') {
          firebase.logout();
        }
        console.log(error);
        f();
      });
    },
    loginWithGoogle: () =>
      firebase.login({ provider: 'google', type: 'popup' }),

    loginWithFaceBook: () =>
      firebase.login({ provider: 'facebook', type: 'popup' }),

    loginWithTwitter: () =>
      firebase.login({ provider: 'twitter', type: 'popup' }),

    register: ({ email, password, firstName, lastName, phone }) =>
      firebase
        .auth()
        .createUserWithEmailAndPassword(email ? email : emailFromPhone(phone), password)
        .then((res) => {
          const displayName = `${firstName} ${lastName}`;
          console.log(res);
          localStorage.setItem('user_auth', JSON.stringify(res.user));
          AWS.config.update({
            region: awsConfig.region,
            accessKeyId: awsConfig.accessKeyId,
            secretAccessKey: awsConfig.secretAccessKey
          });

          const owner = {
            id: res.user.uid,
            phoneNumber: phone,
            photoURL: '',
            email: email ? email : emailFromPhone(phone),
            country: '',
            state: '',
            city: '',
            address: '',
            zipCode: '',
            about: '',
            isPublic: 'true',
            companyName: '',
            posts: [''],
            name: displayName,
            displayName: displayName,
            role: '',
            facebookLink: '',
            instagramLink: '',
            linkedinLink: '',
            twitterLink: ''
          }

          const clientDynamo = new AWS.DynamoDB();
          const param = {
            TableName: 'owners',
            Item: {
              'id' : {S: owner.id},
              'phoneNumber': {S: owner.phoneNumber},
              'photoURL': {S: owner.photoURL},
              'email': {S: owner.email},
              'country': {S: owner.country},
              'state': {S: owner.state},
              'city': {S: owner.city},
              'address': {S: owner.address},
              'zipCode': {S: owner.zipCode},
              'about': {S: owner.about},
              'isPublic': {B: owner.isPublic},
              'companyName': {S: owner.companyName},
              'posts': {SS: owner.posts},
              'name': {S: owner.name},
              'displayName': {S: owner.displayName},
              'role': {S: owner.role},
              'facebookLink': {S: owner.facebookLink},
              'instagramLink': {S: owner.instagramLink},
              'linkedinLink': {S: owner.linkedinLink},
              'twitterLink': {S: owner.twitterLink}
            }
          };
          clientDynamo.putItem(param).promise();
          localStorage.setItem('owner', JSON.stringify(owner));
        }),

    logout: () => {
      firebase.logout();
      // Clear the cache
      if (localStorage.getItem('owner')) {
        localStorage.removeItem('owner');
      }

      if (localStorage.getItem('user_auth')) {
        localStorage.removeItem('user_auth');
      }

      /*if (localStorage.getItem('store_electronics')) {
        localStorage.removeItem('store_electronics');
      }
      if (localStorage.getItem('store_objects')) {
        localStorage.removeItem('store_objects');
      }
      if (localStorage.getItem('store_shoes')) {
        localStorage.removeItem('store_shoes');
      }
      if (localStorage.getItem('motorcycles')) {
        localStorage.removeItem('motorcycles');
      }
      if (localStorage.getItem('automobiles')) {
        localStorage.removeItem('automobiles');
      }
      if (localStorage.getItem('real_estates')) {
        console.log('1');
        localStorage.removeItem('real_estates');
      }
      if (localStorage.getItem('store_clothes')) {
        localStorage.removeItem('store_clothes');
      }
      if (localStorage.getItem('store_accessories')) {
        localStorage.removeItem('store_accessories');
      }

      if (localStorage.getItem('redux-allposts')) {
        localStorage.removeItem('redux-allposts');
      }

      if (localStorage.getItem('redux-allposts')) {
        localStorage.removeItem('redux-allposts');
      }*/

      // Reset
      dispatch(RESET_ACTION);
    },

    resetPassword: (email) => firebase.resetPassword(email),

    updateProfile: ({
      displayName,
      photoURL,
      email,
      phoneNumber,
      country,
      state,
      city,
      address,
      zipCode,
      about,
      isPublic,
      role,
      companyName
    }) =>
      firebase.updateProfile({}).then((res) => {})
  };
}
