import faker from 'faker';
import { sample } from 'lodash';
import { paramCase } from 'change-case';
// utils
import mock from '../utils/mock';
import { mockCars } from '../utils/mockImages';

// ----------------------------------------------------------------------

const PRODUCT_ADDRESS = [
  'Olivier KIA McMasterville',
  'Mercedes-Benz Laval',
  'Mercedes-Benz Laval',
  'Mercedes-Benz Laval',
  'St-Jérôme Chevrolet Buick GMC Corvette',
  'Ferrari Québec à Montréal',
  'Chevrolet Buick GMC West Island',
  'Gravel Chevrolet Buick Cadillac GMC Île-des-Soeurs',
  'Audi West-Island',
  'Chomedey Toyota Laval',
  'Olivier KIA McMasterville',
  'Mercedes-Benz Laval',
  'Audi North-Island',
  'St-Jérôme Chevrolet Buick GMC Corvette',
  'Chomedey Toyota Laval',
  'Olivier KIA McMasterville',
  'Audi West-Island',
  'Gravel Chevrolet Buick Cadillac GMC Île-des-Soeurs',
  'Chevrolet Buick GMC West Island',
  'Chevrolet Buick GMC West Island',
  'Mercedes-Benz Laval',
  'Ferrari Québec à Montréal',
  'Mercedes-Benz Laval',
  'St-Jérôme Chevrolet Buick GMC Corvette'
];

const PRODUCT_NAME = [
  '2016 Kia Rio LX+ A/C - BLUETOOTH',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2019 Mercedes-Benz S560',
  '2020 Mercedes-Benz S560 MB CERTIFIED',
  '2017 BMW X5 XDRIVE35D AWD BI TURBO DIESEL TOIT CUIR',
  '2020 Ferrari 458',
  '2018 GMC Terrain AWD 4dr SLE',
  '2018 Audi A5 Coupe PROGRESSIV',
  '2017 Audi Q3 2.0T PROGRESSIV QUATTRO',
  '2020 Kia Rio LX',
  '2019 Suzuki SX4',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2020 Audi Q5 3.0T QUATTRO',
  '2018 Mercedes-Benz S43 AMG',
  '2009 Suzuki SX4 JX AWD',
  '2018 Kia Rio LX Sport Model',
  '2020 Audi Q3 2.0T PROGRESSIV QUATTRO',
  '2016 Audi Q3 quattro 2.0T Technik',
  '2018 GMC Terrain SLE',
  '2016 GMC Terrain AWD 4dr -SLE-1',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2015 Ferrari 458 Speciale Aperta',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2018 Kia Rio LX+Auto CAMERA DE RECUL'
];

const PRODUCT_DESCRIPTION = `
<p>OUVERT LE SAMEDI! Osez comparer! Chomedey Toyota est la concession par excellence à Laval depuis 40 ans. Offrant une vaste gamme de véhicules d'occasion. Chomedey Toyota saura faire la différence dans votre sélection de véhicule. Prenez rendez-vous avec l'un de nos conseillers sans tarder. 1ere, 2ième, 3ième chance au crédit. Prolongation de garantie à prix concurrentiel. Rapport d'historique Carfax disponible! chomedeytoyota.ca / 1-833-368-1069 ---- OPEN ON SATURDAY! Dare to compare! Chomedey Toyota has been the dealership by excellence in Laval for 40 years. Offering a wide range of used vehicles. Chomedey Toyota will make the difference in your vehicle selection. Make an appointment with one of our advisors without delay. 1st, 2nd, 3rd chance credit. Competitively priced warranty extension. Carfax history report available!
`;

const PRODUCT_SIZE = [
  '6',
  '7',
  '8',
  '8.5',
  '9',
  '9.5',
  '10',
  '10.5',
  '11',
  '11.5',
  '12',
  '13'
];

const PRODUCT_FEATURE = [
  '2016 Kia Rio LX+ A/C - BLUETOOTH',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2019 Mercedes-Benz S560',
  '2020 Mercedes-Benz S560 MB CERTIFIED',
  '2017 BMW X5 XDRIVE35D AWD BI TURBO DIESEL TOIT CUIR',
  '2020 Ferrari 458',
  '2018 GMC Terrain AWD 4dr SLE',
  '2018 Audi A5 Coupe PROGRESSIV',
  '2017 Audi Q3 2.0T PROGRESSIV QUATTRO',
  '2020 Kia Rio LX',
  '2019 Suzuki SX4',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2020 Audi Q5 3.0T QUATTRO',
  '2018 Mercedes-Benz S43 AMG',
  '2009 Suzuki SX4 JX AWD',
  '2018 Kia Rio LX Sport Model',
  '2020 Audi Q3 2.0T PROGRESSIV QUATTRO',
  '2016 Audi Q3 quattro 2.0T Technik',
  '2018 GMC Terrain SLE',
  '2016 GMC Terrain AWD 4dr -SLE-1',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2015 Ferrari 458 Speciale Aperta',
  '2018 Mercedes-Benz S63 AMG MB CERTIFIED',
  '2018 Kia Rio LX+Auto CAMERA DE RECUL'
];

const PRODUCT_BRAND = [
  'Kia',
  'Mercedes-Benz',
  'Mercedes-Benz',
  'Mercedes-Benz',
  'BMW',
  'Ferrari',
  'GMC',
  'Audi',
  'Audi',
  'Kia',
  'Suzuki',
  'Mercedes-Benz',
  'Audi',
  'Mercedes-Benz',
  'Suzuki',
  'Kia',
  'Audi',
  'Audi',
  'GMC',
  'GMC',
  'Mercedes-Benz',
  'Ferrari',
  'Mercedes-Benz',
  'Kia'
];

const PRODUCT_MODEL = [
  'Rio',
  'S63 AMG',
  'S560',
  'S560',
  'X5',
  '458',
  'Terrain',
  'A5',
  'Q3',
  'Rio',
  'SX4',
  'S63',
  'Q5',
  'S43 AMG',
  'SX4',
  'Rio LX',
  'Q3',
  'Q3',
  'Terrain SLE',
  'Terrain',
  'S63',
  '458 Speciale Aperta',
  'S63',
  'Rio'
];

const PRODUCT_YEAR = [
  '2016',
  '2018',
  '2019',
  '2020',
  '2017',
  '2020',
  '2018',
  '2018',
  '2017',
  '2020',
  '2019',
  '2018',
  '2020',
  '2018',
  '2009',
  '2018',
  '2020',
  '2016',
  '2018',
  '2016',
  '2018',
  '2015',
  '2018',
  '2018'
];

const PRODUCT_TRANSMISSION = [
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'automatic',
  'manual',
  'automatic',
  'manual',
  'automatic',
  'automatic',
  'automatic',
  'manual',
  'automatic',
  'manual',
  'automatic',
  'automatic',
  'manual',
  'automatic',
  'manual'
];

const PRODUCT_ENGINE = [
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L',
  '4 Cylinder Engine 2.0L'
];

const PRODUCT_BODY_TYPE = [
  'suv',
  'suv',
  'sedan',
  'truck',
  'van',
  'van',
  'truck',
  'electric',
  'electric',
  'suv',
  'big_truck',
  'suv',
  'suv',
  'sedan',
  'truck',
  'van',
  'van',
  'truck',
  'electric',
  'electric',
  'suv',
  'big_truck',
  'suv',
  'suv'
];

const PRODUCT_CONDITION = [
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new',
  'used',
  'new'
];

const PRODUCT_EXTERIOR_COLOR = [
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black'
];

const PRODUCT_INTERIOR_COLOR = [
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black',
  'Black',
  'White',
  'Bleu',
  'Black'
];

const PRODUCT_FUEL = [
  'Essence',
  'Essence',
  'Disel',
  'Essence',
  'Essence',
  'Essence',
  'Disel',
  'Essence,',
  'Essence',
  'Essence',
  'Disel',
  'Essence',
  'Essence',
  'Essence',
  'Disel',
  'Essence',
  'Essence',
  'Essence',
  'Disel',
  'Essence',
  'Essence',
  'Essence',
  'Disel',
  'Essence'
];

const PRODUCT_DOORS = [
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4',
  '4',
  '2',
  '5',
  '4'
];

const PRODUCT_FEATURES = [
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ],
  [
    '4-Wheel Disc Brakes',
    'Front Head Air Bag',
    'Heated Mirrors',
    'Pass-Through Rear Seat',
    'Power Mirror(s)',
    'Passenger Vanity Mirror',
    'ABS',
    'Brake Assist',
    'Power Outlet',
    'Front Side Air Bag',
    'Front Reading Lamps',
    'Rear Bench Seat',
    'Aluminum Wheels',
    'Rear Head Air Bag',
    'Driver Vanity Mirror',
    'Cargo Shade',
    'Floor Mats'
  ]
];

const productFeature = (index) => PRODUCT_FEATURES[index];

const PRODUCT_COLOR = [
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000'],
  ['#00AB55', '#000000']
];

const PRODUCT_PASSENGERS = [
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5',
  '5'
];

const PRODUCT_TYPE = [
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell',
  'sell',
  'rent',
  'sell',
  'sell'
];

const products = [...Array(24)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: `kl09mbb9-a411-1003-q7r7-5ab154rw1lx9-${setIndex}`,
    cover: mockCars(setIndex),
    images: [...Array(8)].map((_, index) => {
      const setIndex = index + 1;
      return mockCars(setIndex);
    }),
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({
      min: 95000,
      max: 900000,
      precision: 0.01
    }),
    priceSale:
      setIndex % 3
        ? null
        : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    totalRating: faker.datatype.number({ min: 0, max: 5, precision: 0.1 }),
    totalReview: faker.datatype.number(),
    ratings: [...Array(5)].map((_, index) => ({
      name: `${index + 1} Star`,
      starCount: faker.datatype.number(),
      reviewCount: faker.datatype.number()
    })),
    reviews: [...Array(8)].map((_, index) => ({
      id: faker.datatype.uuid(),
      name: faker.name.findName(),
      avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
      comment: faker.lorem.lines(),
      rating: faker.datatype.number({ min: 1, max: 5 }),
      isPurchased: faker.datatype.boolean(),
      helpful: faker.datatype.number({ min: 1, max: 500 }),
      postedAt: faker.date.past()
    })),
    status: sample(['sale', 'new', '', '']),
    inventoryType: sample(['in_stock', 'out_of_stock', 'low_stock']),
    sizes: PRODUCT_SIZE,
    available:
      setIndex % 3 === 0 ? faker.datatype.number({ min: 19, max: 100 }) : 2,
    description: PRODUCT_DESCRIPTION,
    sold: faker.datatype.number(),
    createdAt: faker.date.past(),
    category: sample(['Accessories', 'Apparel', 'Shose']),
    gender: sample(['Men', 'Women', 'Kids']),
    productAddress: PRODUCT_ADDRESS[index],
    bedroom: faker.datatype.number({ min: 1, max: 6 }),
    bathroom: faker.datatype.number({ min: 1, max: 6 }),
    buildYear: faker.datatype.number({ min: 1996, max: 2021 }),
    parking: faker.datatype.number({ min: 1, max: 2 }),
    sqft: faker.datatype.number({ min: 500, max: 1500 }),
    colors: PRODUCT_COLOR[index],
    odometre: faker.datatype.number({ min: 600, max: 140000 }),
    transmission: PRODUCT_TRANSMISSION[index],
    condition: PRODUCT_CONDITION[index],
    brand: PRODUCT_BRAND[index],
    model: PRODUCT_MODEL[index],
    year: PRODUCT_YEAR[index],
    bodyType: PRODUCT_BODY_TYPE[index],
    body: PRODUCT_BODY_TYPE[index],
    fuel: PRODUCT_FUEL[index],
    gas: PRODUCT_FUEL[index],
    features: productFeature(index),
    colorsInterior: PRODUCT_INTERIOR_COLOR[index],
    engine: PRODUCT_ENGINE[index],
    colorsExterior: PRODUCT_EXTERIOR_COLOR[index],
    doors: PRODUCT_DOORS[index],
    passengers: PRODUCT_PASSENGERS[index],
    type: PRODUCT_TYPE[index],
    currency: 'dollar',
    owner: {
      photo: mockCars(setIndex),
      name: 'Cheick Cisse',
      address_:
        'RE/MAX ELITE | 500 rue Notre-Dame Est, Victoriaville, Québec, G6P 4B5',
      email: 'cheickcisse@google.com',
      phone: '819-758-3293',
      id: '8864c717-587d-472a-929a-8e5f298024da-0',
      displayName: 'Jaydon Frankie',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg',
      phoneNumber: '+40 777666555',
      country: 'United States',
      address: '90210 Broadway Blvd',
      state: 'California',
      city: 'San Francisco',
      zipCode: '94116',
      about: '',
      role: 'admin',
      isPublic: true,
      companyName: 'Google'
    },
    me: 'cars'
  };
});

// ----------------------------------------------------------------------

mock.onGet('/api/cars').reply(200, { products });

// ----------------------------------------------------------------------

mock.onGet('/api/cars/step').reply((config) => {
  try {
    const { index, step } = config.params;
    const maxLength = products.length;
    const loadMore = index + step;

    const sortProducts = [...products].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const results = sortProducts.slice(0, loadMore);

    return [200, { results, maxLength }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

// ----------------------------------------------------------------------

mock.onGet('/api/cars/car').reply((config) => {
  try {
    const { id } = config.params;
    const product = products.find((_product) => paramCase(_product.id) === id);

    if (!product) {
      return [404, { message: 'product not found' }];
    }

    return [200, { product }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});
