import { sum, map, filter, uniqBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import * as AWS from "aws-sdk";
import { awsConfig } from '../../config';
// utils
import axios from '../../utils/axios';
import { noCase } from 'change-case';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  product: null,
  sortBy: null,
  hasMore: true,
  index: 0,
  step: 11,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    sqftRange: '',
    rating: '',
    bedroom: [],
    bathroom: []
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null
  }
};

const slice = createSlice({
  name: 'searchposts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
      state.filters.sqftRange = action.payload.sqftRange;
      state.filters.bedroom = action.payload.bedroom;
      state.filters.bathroom = action.payload.bathroom;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = filter(
        state.checkout.cart,
        (item) => item.id !== action.payload
      );

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  getMorePosts
} = slice.actions;

// ----------------------------------------------------------------------

export function getPostsInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/immobiliers/step', {
        params: { index, step }
      });
      const results = response.data.results.length;
      const { maxLength } = response.data;

      dispatch(slice.actions.getPostsInitial(response.data.results));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ---------------------------------------------------------------------

export function getSearchResult(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      // fetch special keyboads

      // fetch real estate
      const res = new Array;
      console.log(name);
      var keys = JSON.parse(localStorage.getItem('real_estates'));
      console.log(keys);
      for (const key in keys) {
        console.log(key);
        console.log(keys[key].name);
        console.log(keys[key].productAddress);
        if (noCase(keys[key].name).includes(name)) {
            res.push(keys[key]);
        } else if (noCase(keys[key].productAddress).includes(name)) {
            res.push(keys[key]);
        } else if (noCase(keys[key].description).includes(name)) {
          res.push(keys[key]);
        }
      }

      // fetch land
      keys = JSON.parse(localStorage.getItem('lands'));
      console.log(keys);
      for (const key in keys) {
        console.log(key);
        console.log(keys[key].name);
        console.log(keys[key].productAddress);
        if (noCase(keys[key].name).includes(name)) {
          res.push(keys[key]);
        } else if (noCase(keys[key].productAddress).includes(name)) {
          res.push(keys[key]);
        } else if (noCase(keys[key].description).includes(name)) {
          res.push(keys[key]);
        }
      }

      // fetch moto
      keys = JSON.parse(localStorage.getItem('motorcycles'));
      console.log(keys);
      for (const key in keys) {
        if (noCase(keys[key].name).includes(name)) {
          res.push(keys[key]);
        } else if (noCase(keys[key].model).includes(name)) {
          res.push(keys[key]);
        } else if (noCase(keys[key].brand).includes(name)) {
          res.push(keys[key]);
        } else if (noCase(keys[key].description).includes(name)) {
          res.push(keys[key]);
        }
      }


      // fetch cars
      keys = JSON.parse(localStorage.getItem('automobiles'));
      console.log(keys);
      for (const key in keys) {
        if (noCase(keys[key].name).includes(name)) {
          res.push(keys[key]);
        } else if (noCase(keys[key].model).includes(name)) {
          res.push(keys[key]);
        } else if (noCase(keys[key].brand).includes(name)) {
          res.push(keys[key]);
        } else if (noCase(keys[key].description).includes(name)) {
          res.push(keys[key]);
        }
      }

      dispatch(slice.actions.getProductsSuccess(res));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

const getRealEstates = (name) => {
  const res = new Array;
  console.log(name);
  const keys = JSON.parse(localStorage.getItem('real_estates'));
  console.log(keys);
  for (const key in keys) {
    console.log(key);
    console.log(keys[key].name);
    console.log(keys[key].productAddress);
    if (noCase(keys[key].name).includes(name)) {
      res.push(keys[key]);
    } else if (noCase(keys[key].productAddress).includes(name)) {
      res.push(keys[key]);
    }
  }
  return res;
}

const getSearchLands = (name) => {
  const res = new Array;
  console.log(name);
  const keys = JSON.parse(localStorage.getItem('lands'));
  console.log(keys);
  for (const key in keys) {
    console.log(key);
    console.log(keys[key].name);
    console.log(keys[key].productAddress);
    if (noCase(keys[key].name).includes(name)) {
      res.push(keys[key]);
    } else if (noCase(keys[key].productAddress).includes(name)) {
      res.push(keys[key]);
    }
  }
  return res;
}
