import { sum, map, filter, uniqBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import * as AWS from "aws-sdk";
import { awsConfig } from '../../config'
import useAuth from '../../hooks/useAuth';
import axios from '../../utils/axios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  product: null,
  sortBy: null,
  hasMore: true,
  index: 0,
  step: 11,
  filters: {
    gender: [],
    transmission: [],
    brand: [],
    category: 'All',
    colors: [],
    priceRange: '',
    odometerRange: '',
    rating: '',
    gas: [],
    year: [],
    body: [],
    condition: []
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null
  }
};

const slice = createSlice({
  name: 'allposts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.transmission = action.payload.transmission;
      state.filters.brand = action.payload.brand;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.odometerRange = action.payload.odometerRange;
      state.filters.rating = action.payload.rating;
      state.filters.gas = action.payload.gas;
      state.filters.year = action.payload.year;
      state.filters.body = action.payload.body;
      state.filters.condition = action.payload.condition;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(
        cart.map((product) => product.price * product.quantity)
      );
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = filter(
        state.checkout.cart,
        (item) => item.id !== action.payload
      );

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total =
        state.checkout.subtotal - state.checkout.discount + shipping;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
  getMorePosts
} = slice.actions;

// ----------------------------------------------------------------------

export function getPostsInitial(index, step) {
  //const { loginWithGoogle, loginWithFaceBook, loginWithTwitter } = useAuth();
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      AWS.config.update({
        region: awsConfig.region,
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
        dynamoDbCrc32: false
      });
      const client = new AWS.DynamoDB();

      // owner
      //localStorage.setItem('user_auth', JSON.stringify(res.user.user));//res.user.user.uid
      const owner = JSON.parse(localStorage.getItem('user_auth'));
      if (!owner) {
        return;
      }
      const paramOwners = {
        TableName: "owners",
        Key: {
          id : {
            'S': owner.uid
          }
        }
      };
      const resultOwners = await client.getItem(paramOwners).promise();
      const dataOwners = AWS.DynamoDB.Converter.unmarshall(resultOwners.Item);

      const params = { RequestItems: {} };
      console.log(dataOwners.posts.values);
      for (const post of dataOwners.posts.values) {
        if (post.includes('electronics')) { //store_electronics
          if (params.RequestItems['store_electronics'] != null) {
            params.RequestItems['store_electronics']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['store_electronics'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
        if (post.includes('objects')) { //store_objects
          if (params.RequestItems['store_objects'] != null) {
            params.RequestItems['store_objects']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['store_objects'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
        if (post.includes('shoes')) { //store_shoes
          if (params.RequestItems['store_shoes'] != null) {
            params.RequestItems['store_shoes']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['store_shoes'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
        if (post.includes('moto')) { //motorcycles
          if (params.RequestItems['motorcycles'] != null) {
            params.RequestItems['motorcycles']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['motorcycles'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
        if (post.includes('automobiles')) { //cars
          if (params.RequestItems['automobiles'] != null) {
            params.RequestItems['automobiles']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['automobiles'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
        if (post.includes('immobilier')) { //real_estates
          if (params.RequestItems['real_estates'] != null) {
            params.RequestItems['real_estates']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['real_estates'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
        if (post.includes('land')) { //lands
          if (params.RequestItems['lands'] != null) {
            params.RequestItems['lands']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['lands'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
        if (post.includes('clothes')) { //store_clothes
          if (params.RequestItems['store_clothes'] != null) {
            params.RequestItems['store_clothes']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['store_clothes'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
        if (post.includes('accessories')) { //store_accessories
          if (params.RequestItems['store_accessories'] != null) {
            params.RequestItems['store_accessories']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['store_accessories'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
        if (post.includes('beauty')) { //store_beauty
          if (params.RequestItems['store_beauty'] != null) {
            params.RequestItems['store_beauty']['Keys'].push(
              { id : { 'S': post } }
            );
          } else {
            params.RequestItems['store_beauty'] = {
              Keys: [ { id : { 'S': post } } ]
            }
          }
        }
      }
      
      if (Object.keys(params.RequestItems).length === 0) {
        return dispatch(slice.actions.getPostsInitial([{isEmpty: true}]));
      }

      // List of owners
      const result = await client.batchGetItem(params).promise();
  
      var resultArray = [];
      console.log(result.Responses);
      for (const [key, value] of Object.entries(result.Responses)) {
        localStorage.setItem(`${key}_edit`, JSON.stringify(value));
        resultArray = resultArray.concat(value);
      }

      const dataArray = new Array;
      for (const item of resultArray) {
        const data = AWS.DynamoDB.Converter.unmarshall(item);
        dataArray.push(data);
      }

      // Sort product list
      const sortProducts = [...dataArray].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      if (sortProducts.length === 0) {
        return dispatch(slice.actions.getPostsInitial([{isEmpty: true}]));
      }

      dispatch(slice.actions.getPostsInitial(sortProducts));
      dispatch(slice.actions.noHasMore());
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
      // SHow error page
      dispatch(slice.actions.getPostsInitial([{isEmpty: true}]));
    }
  };
}

export function getSearchPosts(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/immobiliers');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
