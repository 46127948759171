import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'fcfa',
    label: 'FCFA',
    label_display: 'FCFA',
    icon: '/static/icons/currency/fcfa.svg'
  },
  {
    value: 'euro',
    label: 'Euro',
    label_display: '€',
    icon: '/static/icons/currency/euro.svg'
  },
  {
    value: 'dollar',
    label: 'Dollar',
    label_display: '$',
    icon: '/static/icons/currency/dollar.svg'
  }
];

// ----------------------------------------------------------------------
//async function 
async function localStorageSetItem(key, value) {
  const today = new Date();

  if (today.toLocaleDateString("en-US") !== localStorage.getItem('currencyDate')) {
    localStorage.setItem('currencyDate', today.toLocaleDateString("en-US"));
    const res = await fetch('https://v6.exchangerate-api.com/v6/76c662628718a3af5627b9ee/latest/XOF');
    const data = await res.json();

    localStorage.setItem('fcfa', data['conversion_rates']['XOF']);
    localStorage.setItem('euro', data['conversion_rates']['EUR']);
    localStorage.setItem('dollar', data['conversion_rates']['USD']);
  }

  localStorage.setItem(key, value);
  const event = new Event('itemInserted');
  const getCurrency = (currency) => {
    switch(currency) {
      case 'fcfa':
        return 'XOF';
      case 'euro':
        return 'EUR';
      case 'dollar':
        return 'USD'
      default:
        return 'XOF';
    }
  };

  event.value = value;
  event.key = key;
  event.currencyvalue = localStorage.getItem(`${value}`);
  document.dispatchEvent(event);
};

export default function useCurrency() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('storageCurrency') || 'fcfa';
  const currentCurrency = LANGS.find((_lang) => _lang.value === langStorage);

  const handleChangeLanguage = (newcurr) => {
    localStorageSetItem('storageCurrency', newcurr);
  };

  return {
    onChangeCurrency: handleChangeLanguage,
    translate,
    currentCurrency,
    allLang: LANGS
  };
}
