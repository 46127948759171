import faker from 'faker';
import { sample } from 'lodash';
import { paramCase } from 'change-case';
// utils
import mock from '../utils/mock';
import { mockAccessories } from '../utils/mockImages';

// ----------------------------------------------------------------------

const PRODUCT_NAME = [
  'Super Strong Belt',
  'Hugo Boss Glasses',
  'Very resistent boss watch',
  'Vintage Watch',
  'Boss watch',
  'Sport watch',
  'Parfum Channel N5',
  'Louis Louis Hand bag',
  'Geek glasses',
  'Women glasses',
  'Black womeen purse',
  'Red purse',
  'Black reading glasses',
  'Beach Purse with lots of space',
  'Women very nice watch',
  'Atletic Watch Alert',
  'Gang watch Alert',
  'OMG Dope Sun Glasses',
  'Small Boogie LV Purse Bag',
  'Unknown brand parfum',
  'Silver Bracelet',
  'Small Cream Purse',
  'Parfum Should Smell Good'
];

const PRODUCT_COLOR = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107'
];

const PRODUCT_DESCRIPTION = `
<p><strong><small> SPECIFICATION</small></strong></p>
<p>Leather panels. Laces. Rounded toe. Rubber sole.
<br /><br />
<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>
<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>
`;

const PRODUCT_CATEGORY = [
  'belt',
  'eyewear',
  'watch',
  'watch',
  'watch',
  'watch',
  'parfum',
  'bag',
  'eyewear',
  'eyewear',
  'bag',
  'bag',
  'eyewear',
  'bag',
  'watch',
  'watch',
  'watch',
  'eyewear',
  'bag',
  'parfum',
  'other',
  'watch',
  'bag',
  'parfum'
];

const PRODUCT_SIZE_AVAILABILITY = {
  xs: {
    available: 0,
    us: 'XS',
    eu: 'XS'
  },
  s: {
    available: 0,
    us: 'S',
    eu: 'S'
  },
  m: {
    available: 0,
    us: 'M',
    eu: 'M'
  },
  l: {
    available: 0,
    us: 'L',
    eu: 'L'
  },
  xl: {
    available: 0,
    us: 'XL',
    eu: 'XL'
  },
  0: {
    available: 1,
    us: 'None',
    eu: 'None'
  },
  1: {
    available: 0,
    us: '1',
    eu: '31'
  },
  2: {
    available: 0,
    us: '2',
    eu: '32'
  },
  3: {
    available: 0,
    us: '3',
    eu: '33'
  },
  4: {
    available: 0,
    us: '4',
    eu: '34'
  },
  5: {
    available: 0,
    us: '5',
    eu: '35'
  },
  6: {
    available: 0,
    us: '6',
    eu: '38'
  },
  7: {
    available: 0,
    us: '7',
    eu: '39.5'
  },
  8: {
    available: 0,
    us: '8',
    eu: '40.5'
  },
  8.5: {
    available: 0,
    us: '8.5',
    eu: '41.5'
  },
  9: {
    available: 0,
    us: '9',
    eu: '41.5'
  },
  9.5: {
    available: 0,
    us: '9.5',
    eu: '42.5'
  },
  10: {
    available: 0,
    us: '10',
    eu: '38'
  },
  10.5: {
    available: 0,
    us: '10.5',
    eu: '43.5'
  },
  11: {
    available: 0,
    us: '11',
    eu: '44.5'
  },
  11.5: {
    available: 0,
    us: '11.5',
    eu: '45.5'
  },
  12: {
    available: 0,
    us: '12',
    eu: '46'
  },
  13: {
    available: 0,
    us: '13',
    eu: '47'
  },
  14: {
    available: 0,
    us: '14',
    eu: '48'
  },
  15: {
    available: 0,
    us: '15',
    eu: '49'
  },
  15: {
    available: 0,
    us: '16',
    eu: '50'
  }
};

const products = [...Array(24)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: `fc68bad5-d430-4033-b8f8-4bc069dc0ba0-accessorie-${setIndex}`,
    cover: mockAccessories(setIndex),
    images: [...Array(8)].map((_, index) => {
      const setIndex = index + 1;
      return mockAccessories(setIndex);
    }),
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({ min: 100000, max: 200000, precision: 0.01 }),
    priceSale:
      setIndex % 3
        ? null
        : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    totalRating: faker.datatype.number({ min: 0, max: 5, precision: 0.1 }),
    totalReview: faker.datatype.number(),
    ratings: [...Array(5)].map((_, index) => ({
      name: `${index + 1} Star`,
      starCount: faker.datatype.number(),
      reviewCount: faker.datatype.number()
    })),
    reviews: [...Array(8)].map((_, index) => ({
      id: faker.datatype.uuid(),
      name: faker.name.findName(),
      avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
      comment: faker.lorem.lines(),
      rating: faker.datatype.number({ min: 1, max: 5 }),
      isPurchased: faker.datatype.boolean(),
      helpful: faker.datatype.number({ min: 1, max: 500 }),
      postedAt: faker.date.past()
    })),
    colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,
    status: sample(['sale', 'new', '', '']),
    inventoryType: sample(['in_stock', 'out_of_stock', 'low_stock']),
    sizes: 0,
    sizesAvailability: PRODUCT_SIZE_AVAILABILITY,
    available:
      setIndex % 3 === 0 ? faker.datatype.number({ min: 19, max: 100 }) : 2,
    description: PRODUCT_DESCRIPTION,
    sold: faker.datatype.number(),
    createdAt: faker.date.past(),
    type: 'accessories',
    category: PRODUCT_CATEGORY[index],
    allowBuy: true, // ALLOW_BUY[index],
    editMode: false,
    me: 'boutique',
    owner: {
      photo: mockAccessories(setIndex),
      name: 'Cheick Cisse',
      address_:
        'RE/MAX ELITE | 500 rue Notre-Dame Est, Victoriaville, Québec, G6P 4B5',
      email: 'cheickcisse@google.com',
      phone: '819-758-3293',
      id: '8864c717-587d-472a-929a-8e5f298024da-0',
      displayName: 'Jaydon Frankie',
      photoURL: '/static/mock-images/avatars/avatar_default.jpg',
      phoneNumber: '+40 777666555',
      country: 'United States',
      address: '90210 Broadway Blvd',
      state: 'California',
      city: 'San Francisco',
      zipCode: '94116',
      about: '',
      role: 'admin',
      isPublic: true,
      companyName: 'Google'
    }
  };
});

// ----------------------------------------------------------------------

mock.onGet('/api/accessories').reply(200, { products });

// ----------------------------------------------------------------------

mock.onGet('/api/accessories/accessorie').reply((config) => {
  try {
    const { name } = config.params;
    const product = products.find(
      (_product) => paramCase(_product.name) === name
    );

    if (!product) {
      return [404, { message: 'product not found' }];
    }

    return [200, { product }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});

mock.onGet('/api/accessories/accessorie/id').reply((config) => {
  try {
    const { id } = config.params;
    const product = products.find((_product) => paramCase(_product.id) === id);

    if (!product) {
      return [404, { message: 'product not found' }];
    }

    return [200, { product }];
  } catch (error) {
    console.error(error);
    return [500, { message: 'Internal server error' }];
  }
});