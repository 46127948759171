// ----------------------------------------------------------------------

export const codes = {
  // Email and Password
  invalidEmailOrPassword: {
    code: 'auth/invalid-email' || 'auth/user-disabled' || 'auth/user-not-found',
    text: 'Wrong Credentials.',
    textFr: 'Erreur d\'Identification.',
  },

  // Email
  emailAlreadyinUse: {
    code: 'auth/email-already-in-use',
    text: 'There already exists an account with the given email address.',
    textFr: 'Il existe déjà un compte avec l\'adresse e-mail.',
  },
  invalidEmail: {
    code: 'auth/invalid-email',
    text: 'The email address is not valid.',
    textFr: 'L\'adresse e-mail n\'est pas valide.',
  },
  userDisabled: {
    code: 'auth/user-disabled',
    text: 'The user corresponding to the given credential has been disabled.',
    textFr: 'L\'utilisateur correspondant a été désactivé.',
  },
  userNotFound: {
    code: 'auth/user-not-found',
    text: 'There is no user corresponding to the email address.',
    textFr: 'Il n\'y a pas d\'utilisateur correspondant à l\'adresse e-mail.',
  },

  // Password
  wrongPassword: {
    code: 'auth/wrong-password',
    text: 'Wrong password',
    textFr: 'Mauvais mot de passe',
  },
  weakPassword: {
    code: 'auth/weak-password',
    text: 'Password should be at least 6 characters',
    textFr: 'Le mot de passe doit comporter au moins 6 caractères',
  }
};

const {
  invalidEmailOrPassword,
  emailAlreadyinUse,
  invalidEmail,
  userDisabled,
  userNotFound,
  wrongPassword,
  weakPassword,
} = codes;

export function emailError(errors, lang) {
  return {
    error:
      errors === emailAlreadyinUse.code ||
      errors === invalidEmail.code ||
      errors === userDisabled.code ||
      errors === userNotFound.code,
    helperText:
      (errors === emailAlreadyinUse.code && emailAlreadyinUse.textFr) ||
      (errors === invalidEmail.code && invalidEmail.textFr) ||
      (errors === userDisabled.code && userDisabled.textFr) ||
      (errors === userNotFound.code && userNotFound.textFr)
  };
}

export function passwordError(errors, lang) {
  //const wrongPassword = lang === 'en' ? wrongPassword && wrongPassword.text : wrongPassword && wrongPassword.textFr;
  //const weakPassword = lang === 'en' ? weakPassword && weakPassword.text : weakPassword && weakPassword.textFr;
  return {
    error: errors === wrongPassword.code || errors === weakPassword.code,
    helperText:
      (errors === wrongPassword.code && wrongPassword.textFr) ||
      (errors === weakPassword.code && weakPassword.textFr)
  };
}

export function emailPasswordError(errors, lang) {
  const text = lang === 'en' ?  invalidEmailOrPassword.text : invalidEmailOrPassword.textFr;
  return {
    error: 
      errors === wrongPassword.code || 
      errors === emailAlreadyinUse.code ||
      errors === invalidEmail.code ||
      errors === userDisabled.code ||
      errors === userNotFound.code,
    helperText:
      (errors === wrongPassword.code && text) ||
      (errors === emailAlreadyinUse.code && text) ||
      (errors === invalidEmail.code && text) ||
      (errors === userDisabled.code && text) ||
      (errors === userNotFound.code && text)
  };
}
