// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DOCS = '/docs';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  page404: '/404',
  page500: '/500',
  pageImo: '/immobilier'
};

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  components: '/components',
  dashboard: ROOTS_DASHBOARD,
  explore: `${ROOTS_DASHBOARD}/immobilier`
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    immobilier: path(ROOTS_DASHBOARD, '/immobilier'),
    lands: path(ROOTS_DASHBOARD, '/lands'),
    immobilierRent: path(ROOTS_DASHBOARD, '/immobilier/rent'),
    cars: path(ROOTS_DASHBOARD, '/cars'),
    motorcycles: path(ROOTS_DASHBOARD, '/motorcycles'),
    carsRent: path(ROOTS_DASHBOARD, '/cars/rent'),
    shopshoes: path(ROOTS_DASHBOARD, '/shop/shoes'),
    employments: path(ROOTS_DASHBOARD, '/employments'),
    evenments: path(ROOTS_DASHBOARD, '/evenments'),
    services: path(ROOTS_DASHBOARD, '/services'),
    educations: path(ROOTS_DASHBOARD, '/educations'),
    cloths: path(ROOTS_DASHBOARD, '/shop'),
    shoes: path(ROOTS_DASHBOARD, '/shop/shoes'),
    accessories: path(ROOTS_DASHBOARD, '/shop/accessories'),
    objects: path(ROOTS_DASHBOARD, '/shop/objects'),
    electronics: path(ROOTS_DASHBOARD, '/shop/electronics'),
    search: path(ROOTS_DASHBOARD, '/search/results/:name'),
    beauty: path(ROOTS_DASHBOARD, '/shop/beauty'),
  },
  account: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    allPosts: path(ROOTS_DASHBOARD, '/allposts'),
    newImmobilier: path(ROOTS_DASHBOARD, '/new'),
    newCar: path(ROOTS_DASHBOARD, '/new/car'),
    newBoutique: path(ROOTS_DASHBOARD, '/new/boutique'),
    newEmploi: path(ROOTS_DASHBOARD, '/new/emplois'),
    newEvenement: path(ROOTS_DASHBOARD, '/new/evenement'),
    newService: path(ROOTS_DASHBOARD, '/new/Services'),
    editImmobilier: path(ROOTS_DASHBOARD, '/edit/immobilier/:name'),
    editBoutique: path(ROOTS_DASHBOARD, '/edit/shop/:name'),
    editCar: path(ROOTS_DASHBOARD, '/edit/cars/:name'),
    editRoot: path(ROOTS_DASHBOARD, '/edit'),
    newMotorcycle: path(ROOTS_DASHBOARD, '/new/motorcycle'),
    editMotorcycle: path(ROOTS_DASHBOARD, '/edit/motorcycle/:name'),
    editLand: path(ROOTS_DASHBOARD, '/edit/land/:name'),
    newLand: path(ROOTS_DASHBOARD, '/new/land'),
    analytics: path(ROOTS_DASHBOARD, '/account/analytics'),
  },
  product: path(ROOTS_DASHBOARD, '/product/:name'),
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
    labels: [
      path(ROOTS_DASHBOARD, '/mail/label/:customLabel/:mailId?'),
      path(ROOTS_DASHBOARD, '/mail/:systemLabel/:mailId?')
    ]
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: [
      path(ROOTS_DASHBOARD, '/chat/new'),
      path(ROOTS_DASHBOARD, '/chat/:conversationKey')
    ]
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/card'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  immobilier: {
    root: path(ROOTS_DASHBOARD, '/immobilier'),
    shop: path(ROOTS_DASHBOARD, '/immobilier/shop'),
    product: path(ROOTS_DASHBOARD, '/immobilier/product/:name'),
    product_edit: path(ROOTS_DASHBOARD, '/immobilier/product/edit/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/immobilier/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/immobilier/list'),
    checkout: path(ROOTS_DASHBOARD, '/immobilier/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/immobilier/invoice')
  },
  motorcycles: {
    root: path(ROOTS_DASHBOARD, '/motorcycles'),
    shop: path(ROOTS_DASHBOARD, '/motorcycles/shop'),
    product: path(ROOTS_DASHBOARD, '/motorcycles/product/:name'),
    product_edit: path(ROOTS_DASHBOARD, '/motorcycles/product/edit/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/motorcycles/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/motorcycles/list'),
    checkout: path(ROOTS_DASHBOARD, '/motorcycles/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/motorcycles/invoice')
  },
  lands: {
    root: path(ROOTS_DASHBOARD, '/lands'),
    shop: path(ROOTS_DASHBOARD, '/lands/shop'),
    product: path(ROOTS_DASHBOARD, '/lands/product/:name'),
    product_edit: path(ROOTS_DASHBOARD, '/lands/product/edit/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/lands/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/lands/list'),
    checkout: path(ROOTS_DASHBOARD, '/lands/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/lands/invoice')
  },
  cars: {
    root: path(ROOTS_DASHBOARD, '/cars'),
    shop: path(ROOTS_DASHBOARD, '/cars/shop'),
    product: path(ROOTS_DASHBOARD, '/cars/product/:name'),
    product_edit: path(ROOTS_DASHBOARD, '/cars/product/edit/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/cars/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/cars/list'),
    checkout: path(ROOTS_DASHBOARD, '/cars/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/cars/invoice')
  },
  carsrent: {
    root: path(ROOTS_DASHBOARD, '/cars'),
    shop: path(ROOTS_DASHBOARD, '/cars/shop'),
    product: path(ROOTS_DASHBOARD, '/cars/rent/product/:name'),
    product_edit: path(ROOTS_DASHBOARD, '/cars/rent/product/edit/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/cars/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/cars/list'),
    checkout: path(ROOTS_DASHBOARD, '/cars/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/cars/invoice')
  },
  immobilierRent: {
    root: path(ROOTS_DASHBOARD, '/immobilier'),
    shop: path(ROOTS_DASHBOARD, '/immobilier/shop'),
    product: path(ROOTS_DASHBOARD, '/immobilier/rent/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/immobilier/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/immobilier/list'),
    checkout: path(ROOTS_DASHBOARD, '/immobilier/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/immobilier/invoice')
  },
  shopshoes: {
    root: path(ROOTS_DASHBOARD, '/shop/shoes'),
    shoes: path(ROOTS_DASHBOARD, '/shop/shoes'),
    shop: path(ROOTS_DASHBOARD, '/shop'),
    product: path(ROOTS_DASHBOARD, '/shop/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/cars/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/cars/list'),
    checkout: path(ROOTS_DASHBOARD, '/cars/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/cars/invoice')
  },
  shop: {
    root: path(ROOTS_DASHBOARD, '/shop'),
    product_edit: path(ROOTS_DASHBOARD, '/shop/product/edit/:type/:name'),
    cloths: path(ROOTS_DASHBOARD, '/shop'),
    shoes: path(ROOTS_DASHBOARD, '/shop/shoes'),
    accessories: path(ROOTS_DASHBOARD, '/shop/accessories'),
    objects: path(ROOTS_DASHBOARD, '/shop/objects'),
    electronics: path(ROOTS_DASHBOARD, '/shop/electronics'),
    beauty: path(ROOTS_DASHBOARD, '/shop/beauty'),
    shop: path(ROOTS_DASHBOARD, '/shop'),
    product: path(ROOTS_DASHBOARD, '/shop/product/:type/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/cars/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/cars/list'),
    checkout: path(ROOTS_DASHBOARD, '/cars/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/cars/invoice')
  },
  evenments: {
    root: path(ROOTS_DASHBOARD, '/evenments'),
    shop: path(ROOTS_DASHBOARD, '/evenments/shop'),
    product: path(ROOTS_DASHBOARD, '/evenments/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/cars/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/evenments/list'),
    checkout: path(ROOTS_DASHBOARD, '/evenments/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/evenments/invoice')
  },
  employments: {
    root: path(ROOTS_DASHBOARD, '/employments'),
    shop: path(ROOTS_DASHBOARD, '/employments/shop'),
    product: path(ROOTS_DASHBOARD, '/employments/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/cars/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/employments/list'),
    checkout: path(ROOTS_DASHBOARD, '/employments/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/employments/invoice')
  },
  services: {
    root: path(ROOTS_DASHBOARD, '/services'),
    shop: path(ROOTS_DASHBOARD, '/services/shop'),
    product: path(ROOTS_DASHBOARD, '/services/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/cars/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/services/list'),
    checkout: path(ROOTS_DASHBOARD, '/services/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/services/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(
      ROOTS_DASHBOARD,
      '/blog/post/portfolio-review-is-this-portfolio-too-creative'
    ),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  foundations: {
    root: path(ROOTS_DASHBOARD, '/foundations'),
    colors: path(ROOTS_DASHBOARD, '/foundations/colors'),
    typography: path(ROOTS_DASHBOARD, '/foundations/typography'),
    shadows: path(ROOTS_DASHBOARD, '/foundations/shadows'),
    grid: path(ROOTS_DASHBOARD, '/foundations/grid'),
    icons: path(ROOTS_DASHBOARD, '/foundations/icons')
  },
  components: {
    root: path(ROOTS_DASHBOARD, '/components'),
    accordion: path(ROOTS_DASHBOARD, '/components/accordion'),
    alert: path(ROOTS_DASHBOARD, '/components/alert'),
    autocomplete: path(ROOTS_DASHBOARD, '/components/autocomplete'),
    avatar: path(ROOTS_DASHBOARD, '/components/avatars'),
    badge: path(ROOTS_DASHBOARD, '/components/badges'),
    breadcrumbs: path(ROOTS_DASHBOARD, '/components/breadcrumbs'),
    buttons: path(ROOTS_DASHBOARD, '/components/buttons'),
    chip: path(ROOTS_DASHBOARD, '/components/chips'),
    dialog: path(ROOTS_DASHBOARD, '/components/dialogs'),
    textfield: path(ROOTS_DASHBOARD, '/components/text-fields'),
    label: path(ROOTS_DASHBOARD, '/components/labels'),
    lists: path(ROOTS_DASHBOARD, '/components/lists'),
    menu: path(ROOTS_DASHBOARD, '/components/menu'),
    pagination: path(ROOTS_DASHBOARD, '/components/pagination'),
    pickers: path(ROOTS_DASHBOARD, '/components/pickers'),
    popover: path(ROOTS_DASHBOARD, '/components/popover'),
    progress: path(ROOTS_DASHBOARD, '/components/progress'),
    rating: path(ROOTS_DASHBOARD, '/components/rating'),
    selectionControls: path(ROOTS_DASHBOARD, '/components/selection-controls'),
    snackbar: path(ROOTS_DASHBOARD, '/components/snackbars'),
    slider: path(ROOTS_DASHBOARD, '/components/slider'),
    stepper: path(ROOTS_DASHBOARD, '/components/steppers'),
    tabs: path(ROOTS_DASHBOARD, '/components/tabs'),
    table: path(ROOTS_DASHBOARD, '/components/table'),
    timeline: path(ROOTS_DASHBOARD, '/components/timeline'),
    tooltip: path(ROOTS_DASHBOARD, '/components/tooltips'),
    transferList: path(ROOTS_DASHBOARD, '/components/transfer-list'),
    treeView: path(ROOTS_DASHBOARD, '/components/tree-view'),

    // Extra
    extraComponents: path(ROOTS_DASHBOARD, '/extra-components'),
    chart: path(ROOTS_DASHBOARD, '/extra-components/chart'),
    map: path(ROOTS_DASHBOARD, '/extra-components/map'),
    editor: path(ROOTS_DASHBOARD, '/extra-components/editor'),
    copyToClipboard: path(
      ROOTS_DASHBOARD,
      '/extra-components/copy-to-clipboard'
    ),
    upload: path(ROOTS_DASHBOARD, '/extra-components/upload'),
    carousel: path(ROOTS_DASHBOARD, '/extra-components/carousel'),
    multiLanguage: path(ROOTS_DASHBOARD, '/extra-components/multi-language'),
    animate: path(ROOTS_DASHBOARD, '/extra-components/animate')
  }
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  howItWorks: path(ROOTS_DOCS, '/how-it-work'),
  started: path(ROOTS_DOCS, '/getting-started'),
  package: path(ROOTS_DOCS, '/package'),

  conduct: path(ROOTS_DOCS, '/conduct'),
  aboutus: path(ROOTS_DOCS, '/about-us'),
  privacy: path(ROOTS_DOCS, '/privacy'),
  security: path(ROOTS_DOCS, '/security'),
  contactus: path(ROOTS_DOCS, '/contact-us'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog')
};
