export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const firebaseConfig2 = {
  apiKey: "AIzaSyAB5443-1fTtjA5meMWU474COeWK2tvtkI",
  authDomain: "nested-84056.firebaseapp.com",
  projectId: "nested-84056",
  storageBucket: "nested-84056.appspot.com",
  messagingSenderId: "247506119811",
  appId: "1:247506119811:web:7f3b832e5f3dd42ca3ffb6",
  measurementId: "G-YFCD55EVJB"
};

export const awsConfig = {
  region: "eu-west-2",
  accessKeyId: "AKIA3LSDR36ZVJZYM3U6",
  secretAccessKey: "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"
};

export const awsConfigDynamoDbCrc32 = {
  region: "eu-west-2",
  accessKeyId: "AKIA3LSDR36ZVJZYM3U6",
  secretAccessKey: "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"
};

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = "G-2HWDEKSVPS"; //process.env.REACT_APP_GA_MEASUREMENT_ID;
