import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import immobilierReducer from './slices/immobilier';
import carsReducer from './slices/cars_sell';
import allpostsReducer from './slices/all_posts';
import motorcyclesReducer from './slices/motorcycles';
import accessoriesReducer from './slices/accessories';
import electronicsReducer from './slices/electronics';
import objectsReducer from './slices/objects';
import clothesReducer from './slices/cloths';
import shoesReducer from './slices/shoes'; 
import beautyReducer from './slices/shoes'; 
import landReducer from './slices/land';
import searchpostsReducer from './slices/search_posts';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const accessoriesPersistConfig = {
  key: 'accessories',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const beautyPersistConfig = {
  key: 'beauty',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const electronicsPersistConfig = {
  key: 'electronics',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const objectsPersistConfig = {
  key: 'objects',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const carsPersistConfig = {
  key: 'cars',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const authPersistConfig = {
  key: 'authJwt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const allpostsPersistConfig = {
  key: 'allposts',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const searchpostsPersistConfig = {
  key: 'searchposts',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const shoesPersistConfig = {
  key: 'shoes',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
}

const clothesPersistConfig = {
  key: 'clothes',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
}

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  immobilier: persistReducer(productPersistConfig, immobilierReducer),
  cars: persistReducer(carsPersistConfig, carsReducer),
  allposts: persistReducer(allpostsPersistConfig, allpostsReducer),
  motorcycles: motorcyclesReducer,
  land: landReducer,
  searchposts: persistReducer(searchpostsPersistConfig, searchpostsReducer),
  accessories: persistReducer(accessoriesPersistConfig, accessoriesReducer),
  electronics: persistReducer(electronicsPersistConfig, electronicsReducer),
  objects: persistReducer(objectsPersistConfig, objectsReducer),
  clothes: persistReducer(clothesPersistConfig, clothesReducer),
  shoes: persistReducer(shoesPersistConfig, shoesReducer),
  beauty: persistReducer(beautyPersistConfig, beautyReducer),
});

export { rootPersistConfig, rootReducer };
