import { Icon } from '@iconify/react';
import { useState } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { paramCase } from 'change-case';
// material
import { experimentalStyled as styled, alpha } from '@material-ui/core/styles';
import {
  Box,
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener
} from '@material-ui/core';
// components
import { MIconButton } from '../../components/@material-extend';
import { PATH_DASHBOARD } from '../../routes/paths';
import useLocales from '../../hooks/useLocales';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

const SearchbarStyle = styled('div')(({ theme }) => ({
  //top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('lg')]: {
    height: APPBAR_MOBILE,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar3() {
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const history = useHistory();
  const { currentLang } = useLocales();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearch(event.target.value);
      showStuff();
    }
  };

  const linkTo = `${PATH_DASHBOARD.root}/search/results/${paramCase(search)}`;

  const showStuff = () => {
    history.push(linkTo);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
          <SearchbarStyle>
          <div
            onKeyDown={handleChangeSearch}
          >    
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder={currentLang.value === 'en' ? 'Search…' : 'Recherche...'}
              value={search}
              onChange={handleChangeSearch}
              onKeyDown={handleChangeSearchKeyDown}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ 
                mr: 10,
                fontWeight: 'fontWeightBold'
              }}
            />
            </div>
            <Button 
              variant="contained"
              onClick={showStuff}
              sx={{
                marginLeft: 'auto',
              }}
            >
              {currentLang.value === 'en' ? 'Search' : 'Recherche'}
            </Button>
          </SearchbarStyle>
      </div>
    </ClickAwayListener>
  );
}
