// ----------------------------------------------------------------------

export const mockImgCover = (index) =>
  `/static/mock-images/covers/cover_${index}.jpg`;

export const mockImgFeed = (index) =>
  `/static/mock-images/feeds/feed_${index}.jpg`;

export const mockImgProduct = (index) =>
  `/static/mock-images/products/product_${index}.jpg`;

export const mockImgAvatar = (index) =>
  `/static/mock-images/avatars/avatar_${index}.jpg`;

export const mockImo = (index) => `/static/mock-images/imo/imo_${index}.jpeg`;

export const mockCars = (index) =>
  `/static/mock-images/cars/cars-${index}.jpeg`;

export const mockClothes = (index) => 
  `/static/mock-images/clothes/clothes_${index}.jpeg`;

export const mockAccessories = (index) => 
  `/static/mock-images/accessories/accessories_${index}.jpeg`;

export const mockElectronics = (index) => 
  `/static/mock-images/electronics/electronics_${index}.jpeg`;

export const mockMoto = (index) => 
  `/static/mock-images/moto/moto_${index}.jpeg`;

export const mockObject = (index) => 
  `/static/mock-images/object/object_${index}.jpeg`;
