// routes
import { PATH_DASHBOARD, PATH_PAGE, PATH_AUTH } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

import useLocales from '../../hooks/useLocales';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  map: getIcon('ic_map'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  page: getIcon('ic_page'),
  user: getIcon('ic_user'),
  copy: getIcon('ic_copy'),
  error: getIcon('ic_error'),
  charts: getIcon('ic_charts'),
  editor: getIcon('ic_editor'),
  upload: getIcon('ic_upload'),
  animate: getIcon('ic_animate'),
  calendar: getIcon('ic_calendar'),
  elements: getIcon('ic_elements'),
  carousel: getIcon('ic_carousel'),
  language: getIcon('ic_language'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  components: getIcon('ic_components'),
  authenticator: getIcon('ic_authenticator'),
  immobilier: getIcon('home-outline'),
  home: getIcon('home'),
  car: getIcon('car'),
  shoppingCart: getIcon('shopping-cart'),
  blog2: getIcon('blog'),
  headhunting: getIcon('headhunting'),
  education: getIcon('open-book'),
  customerSupport: getIcon('customer-support'),
  moto: getIcon('moto'),
  cropAlt: getIcon('crop-alt')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'Real Estates',
        href: PATH_DASHBOARD.general.immobilier,
        icon: ICONS.home,
        items: [
          {
            title: 'Buy',
            href: PATH_DASHBOARD.general.immobilier
          },
          {
            title: 'Rent',
            href: PATH_DASHBOARD.general.immobilierRent
          }
        ]
      },
      {
        title: 'Land',
        href: PATH_DASHBOARD.general.lands,
        icon: ICONS.cropAlt
      },
      {
        title: 'Automobiles',
        href: PATH_DASHBOARD.general.cars,
        icon: ICONS.car,
        items: [
          {
            title: 'Buy',
            href: PATH_DASHBOARD.general.cars
          },
          {
            title: 'Rent',
            href: PATH_DASHBOARD.general.carsRent
          }
        ]
      },
      {
        title: 'Motorcycles',
        href: PATH_DASHBOARD.general.motorcycles,
        icon: ICONS.moto
      },
      /*{
        title: 'Store',
        href: PATH_DASHBOARD.shop.cloths,
        icon: ICONS.shoppingCart,
        items: [
          {
            title: 'Clothes',
            href: PATH_DASHBOARD.shop.cloths
          },
          {
            title: 'Shoes',
            href: PATH_DASHBOARD.shop.shoes
          },
          {
            title: 'Beauty / Personal Care',
            href: PATH_DASHBOARD.shop.beauty
          },
          {
            title: 'Accessories',
            href: PATH_DASHBOARD.shop.accessories
          },
          {
            title: 'Objects',
            href: PATH_DASHBOARD.shop.objects
          },
          {
            title: 'Electronics',
            href: PATH_DASHBOARD.shop.electronics
          }
        ]
      },*/
      {
        title: 'Jobs',
        href: PATH_DASHBOARD.general.employments,
        icon: ICONS.headhunting
      },
      {
        title: 'Events',
        href: PATH_DASHBOARD.general.evenments,
        icon: ICONS.calendar
      },
      {
        title: 'Services',
        href: PATH_DASHBOARD.general.services,
        icon: ICONS.customerSupport
      }
    ]
  },
  {
    subheader: 'Account',
    icon: ICONS.blog2,
    href: PATH_DASHBOARD.blog.root,
    items: [
      {
        title: 'My Posts',
        href: PATH_DASHBOARD.account.allPosts
      },
      {
        title: 'Analytics',
        href: PATH_DASHBOARD.account.analytics
      },
      {
        title: 'New Post',
        href: PATH_DASHBOARD.account.newImmobilier,
        items: [
          {
            title: 'Real Estate',
            href: PATH_DASHBOARD.account.newImmobilier
          },
          {
            title: 'Land',
            href: PATH_DASHBOARD.account.newLand
          },
          {
            title: 'Automobile',
            href: PATH_DASHBOARD.account.newCar
          },
          {
            title: 'Motorcycle',
            href: PATH_DASHBOARD.account.newMotorcycle
          },
          /*{
            title: 'Store',
            href: PATH_DASHBOARD.account.newBoutique
          }*/
        ]
      },
      {
        title: 'Settings',
        href: PATH_DASHBOARD.user.account
      }
    ]
  }
];

export default sidebarConfig;
